import {useEffect, useState} from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {fetchLeaderboard, searchProfiles} from '../redux/leaderboardSlice';
import { RootState } from '../../../app/store';
import {debounce} from "@telegram-apps/telegram-ui/dist/helpers/fuctions";

export const useLeaderboardData = () => {
  const dispatch = useAppDispatch();
  const leaderboard = useAppSelector((state: RootState) => state.leaderboard.leaderboard);
  const loading = useAppSelector((state: RootState) => state.leaderboard.loading);
  const error = useAppSelector((state: RootState) => state.leaderboard.error);

  const searchResult = useAppSelector((state: RootState) => state.leaderboard.searchResult);
  const [searchLoading, setSearchLoading] = useState(false);

  useEffect(() => {
    dispatch(fetchLeaderboard());
  }, [dispatch]);

  const debouncedSearch = debounce(async (term) => {
    await dispatch(searchProfiles(term));
    setSearchLoading(false);
  }, 1000);

  const handleSearchProfiles = async (term: string) =>{
    setSearchLoading(true);

    if (term) {
      debouncedSearch(term);
    } else {
      await dispatch(searchProfiles(term));
      setSearchLoading(false);
    }
  }

  return { leaderboard, loading, error, searchResult, searchLoading, handleSearchProfiles };
};
