import {mockMode} from "../../../config";
import {TasksResponse} from "../../../api/types";
import {claimTask, fetchTasks} from "../../../api/userApi";
import {mockTasks} from "../../../api/mocks/mocks";

export const fetchTasksData = async (): Promise<TasksResponse> => {
  if (mockMode) {
    return new Promise((resolve) =>
      setTimeout(() => resolve({
        tasks: mockTasks
      }), 1000)
    );
  } else {
    const response = await fetchTasks();
    return response.data;
  }
}

export const claim = async (taskId: number): Promise<void> => {
  if (mockMode) {
    return new Promise((resolve) => setTimeout(() => resolve(), 1000));
  } else {
    const response = await claimTask(taskId);
    return response.data;
  }
}
