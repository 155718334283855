import React, {useMemo, useState} from 'react';
import LeaderboardItem from './LeaderboardItem';
import LeaderboardTopItem from './LeaderboardTopItem';
import {useLeaderboardData} from '../hooks/useLeaderboardData';
import {IconButton, Input, Spinner, Text} from '@telegram-apps/telegram-ui';
import Diamond from '../assets/Diamond.json';
import Lottie from "lottie-react";
import LeaderboardHowItWorks from "./LeaderboardHowItWorks";
import {IconSearch} from "../assets/IconSearch";
import './Leaderboard.css';
import {Icon24Close} from "@telegram-apps/telegram-ui/dist/icons/24/close";

const Leaderboard: React.FC = () => {
  const {leaderboard, loading, error, searchLoading, searchResult, handleSearchProfiles} = useLeaderboardData();
  const [searchTerm, setSearchTerm] = useState('');
  const [showSearch, setShowSearch] = useState(false);

  const topBalance = useMemo(() => leaderboard?.profiles.length ? leaderboard?.profiles[0].balance : 0, [leaderboard?.profiles]);

  const toggleSearch = () => {
    setShowSearch(searching => {
      if (!searching) {
        setSearchTerm('');
      }
      return !searching;
    });
  }

  const handleSearchChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    await handleSearchProfiles(e.target.value);
  }

  return (
    <div className='container' style={{
      height: '100dvh',
      display: 'flex',
      flexDirection: 'column',
      padding: '0',
      overflowY: "scroll"
    }}>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '24px 16px 12px 16px',
        gap: '16px',
      }}>
        <div style={{display: "flex"}}>
          {!showSearch && <div style={{flexGrow: 1, flexBasis: 0}}/>}
          <div style={{
            flexGrow: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            flexBasis: 0
          }}>
            {!showSearch && (
              <>
                <Lottie animationData={Diamond} loop={true} style={{width: '44px', height: '44px'}}/>
                <Text weight={'1'} style={{fontSize: '24px'}}>Global Top</Text>
                <Text style={{fontSize: '13px'}}>by their social networth</Text>
              </>
            )}

            {showSearch && !loading && (
              <div className="leaderboard-search-wrapper">
                <Input
                  type="text"
                  placeholder="Search profiles..."
                  value={searchTerm}
                  autoFocus
                  onChange={handleSearchChange}
                  after={(searchTerm && <IconButton mode="plain" onClick={() => setSearchTerm('')}>
                    <Icon24Close/>
                  </IconButton>)}
                />
              </div>
            )}
          </div>
          <div style={{
            flexGrow: showSearch ? 0 : 1,
            flexBasis: 0,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-start'
          }}>
            <IconButton mode={showSearch ? 'bezeled' : 'plain'} style={{borderRadius: '50%'}} onClick={toggleSearch}>
              <IconSearch/>
            </IconButton>
          </div>
        </div>

        {!showSearch && <LeaderboardHowItWorks/>}

        {!loading && !error && leaderboard?.me && !showSearch && <LeaderboardTopItem item={leaderboard.me}
                                                                                     bottomPosition={leaderboard?.profiles ? leaderboard?.profiles.slice(-1)[0].position : 0}/>}
      </div>

      {!!error && !loading && !showSearch && <Text>Error: {error}</Text>}

      {(
        (showSearch && !!searchTerm && searchResult) ||
        ((!showSearch || !searchTerm) && !loading && !error && leaderboard?.profiles) ||
        []
      ).map((item) => (
        <LeaderboardItem key={item.id} item={item} topBalance={topBalance}/>
      ))}

      {(
          (showSearch && !!searchTerm && !searchLoading && !searchResult.length) ||
          (!showSearch && !loading && !error && !leaderboard?.profiles.length)
        ) &&
        <div style={{display: "flex", justifyContent: 'center', alignItems: 'center', padding: 16, opacity: 0.5}}>
          <Text weight="3">No results</Text>
        </div>}

      {(loading || searchLoading) && (
        <div
          style={{
            height: "100dvh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}>
          <Spinner size="l"/>
        </div>)}
    </div>
  );
};

export default Leaderboard;
