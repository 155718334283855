import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import tabsReducer from '../features/navigation/tabBar/redux/tabsSlice';
import feedReducer from '../features/feed/redux/feedSlice';
import leaderboardReducer from '../features/leaderboard/redux/leaderboardSlice';
import userReducer from '../features/profile/redux/profileSlice';
import navigationStackReducer from '../features/navigation/stack/redux/navigationStackSlice';
import creationReducer from '../features/create/redux/creationSlice';
import tasksReducer from '../features/tasks/redux/tasksSlice';

export const store = configureStore({
  reducer: {
    tabs: tabsReducer,
    navigationStack: navigationStackReducer,
    feed: feedReducer,
    leaderboard: leaderboardReducer,
    profile: userReducer,
    create: creationReducer,
    tasks: tasksReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
