import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import {fetchLeaderboardData, search} from '../services/leaderboardService';
import {LeaderboardResponse, ProfilesResponse, User} from '../../../api/types';

interface LeaderboardState {
  leaderboard: LeaderboardResponse | null;
  loading: boolean;
  error: string | null;

  searchResult: User[];
}

const initialState: LeaderboardState = {
  leaderboard: null,
  loading: true,
  error: null,

  searchResult: []
};

export const fetchLeaderboard = createAsyncThunk(
  'leaderboard/fetchLeaderboard',
  async () => {
    const data = await fetchLeaderboardData();
    return data;
  }
);

export const searchProfiles = createAsyncThunk(
  'leaderboard/search',
  async (term: string) => {
    if (!term) {
      return { profiles: [] };
    }
    const data = await search(term);
    return data;
  }
);

const leaderboardSlice = createSlice({
  name: 'leaderboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLeaderboard.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchLeaderboard.fulfilled, (state, action: PayloadAction<LeaderboardResponse>) => {
        state.loading = false;
        state.leaderboard = {
          ...action.payload,
          profiles: action.payload.profiles.slice().sort((a, b) => a.position - b.position)
        };
      })
      .addCase(fetchLeaderboard.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch leaderboard';
      })
      .addCase(searchProfiles.fulfilled, (state, action: PayloadAction<ProfilesResponse>) => {
        state.searchResult = action.payload.profiles.slice().sort((a, b) => a.position - b.position);
      })
    ;
  },
});

export default leaderboardSlice.reducer;
