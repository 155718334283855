import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import {Avatar, Button, IconButton, Spinner, Text, Textarea} from '@telegram-apps/telegram-ui';
import {formatter} from '../../../app/helpers';
import {useProfileData} from '../../profile';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import {createPost, createRepost, setInputText, uploadFile} from '../redux/creationSlice';
import './Create.css';
import {IconMedia} from "../assets/IconMedia";
import {Icon24Close} from "@telegram-apps/telegram-ui/dist/icons/24/close";
import {AnimatePresence, Reorder} from 'framer-motion';
import {determineCurrentTab, goBackInTab} from "../../navigation/stack/redux/navigationStackSlice";
import {fetchFeed} from "../../feed";
import FeedItem from "../../feed/components/FeedItem";
import '../../../app/ui/assets/repost.css';
import {IconDot} from "../../../app/ui/components/IconDot";

interface AttachedImage {
  id: number;
  url: string;
  isUploading: boolean;
}

interface CreateParams extends Record<string, string | undefined> {
  repostId?: string;
}

const Create: React.FC = () => {
  const {repostId} = useParams<CreateParams>();
  const {profile} = useProfileData(null);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const text = useAppSelector((state) => state.create.text);
  const media = useAppSelector((state) => state.create.media);

  const [images, setImages] = useState<AttachedImage[]>([]);
  const [imageCounter, setImageCounter] = useState(0);
  const [isUploadingPost, setIsUploadingPost] = useState(false);

  const repostedPost = useAppSelector((state) => repostId ? state.feed.feeds.all.items.find((post) => post.id === parseInt(repostId)) : undefined);

  useEffect(() => {
    if (!repostedPost) {
      dispatch(fetchFeed({name: 'all'}));
    }
  }, [dispatch, repostedPost]);

  const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.target.style.height = 'inherit';
    event.target.style.height = `${event.target.scrollHeight}px`;
    dispatch(setInputText(event.target.value));
  };

  const handleIconMediaClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const fileURL = URL.createObjectURL(file);

      // Add the image to the state with isUploading set to true
      setImages((prevImages) => [
        // ...prevImages, // uncomment when multiple images are supported
        {url: fileURL, isUploading: true, id: imageCounter}
      ]);
      setImageCounter((prevId) => prevId + 1);

      event.target.value = '';

      // Dispatch the upload action
      const resultAction = await dispatch(uploadFile({file}));

      if (uploadFile.fulfilled.match(resultAction)) {
        // Update the state to set isUploading to false and update the image URL
        setImages((prevImages) =>
          prevImages.map((image) =>
            image.url === fileURL
              ? {...image, isUploading: false}
              : image
          )
        );
      } else {
        console.error('Upload failed:', resultAction.error);
      }
    }
  };

  const deleteImage = (index: number) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const handlePost = async () => {
    setIsUploadingPost(true);
    if (repostId) {
      await dispatch(createRepost({repostId, text: text.trim(), media}));
    } else {
      await dispatch(createPost({text: text.trim(), media}));
    }

    await dispatch(fetchFeed({name: 'all'}));

    const tab = determineCurrentTab(location.pathname);
    if (tab) {
      dispatch(goBackInTab({tab, navigate}));
    }
  };

  return (
    <div className="container"
         style={{display: 'flex', flexDirection: 'column', flexGrow: 1, padding: 0, height: '100vh'}}>
      <div
        style={{
          padding: '16px',
          border: 'none',
          background: 'var(--tgui--background_color)',
          color: 'var(--tgui--text_color)',
          display: 'flex',
          flexDirection: 'row',
          gap: '8px',
          alignItems: 'center',
        }}>
        {profile ? <Avatar src={profile.avatar} size={28}/> : <div style={{width: 24, height: 24, display: 'flex'}}><Spinner size={'s'}/></div>}
        <div style={{width: '100%', display: 'flex', flexDirection: 'column', gap: '0px'}}>
          <Text weight='2'
                style={{lineHeight: '21px', fontSize: '16px'}}>{profile ? [profile.first_name ?? '', profile.last_name ?? ''].join(' ').trim() : 'Loading...'}</Text>
          <div style={{display: 'flex', flexDirection: 'row', gap: '8px'}}>
            <div style={{display: 'flex', flexDirection: 'row', gap: '2px', alignItems: 'center'}}>
              <IconDot color={'green'} />
              <Text weight='1' style={{
                lineHeight: '13px',
                fontSize: '10px',
                color: 'var(--tgui--hint_color)'
              }}>{`${formatter.format(profile?.balance ?? 0)}`}</Text>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', gap: '2px', alignItems: 'center'}}>
              <IconDot color={'orange'} />
              <Text weight='1' style={{
                lineHeight: '13px',
                fontSize: '10px',
                color: 'var(--tgui--hint_color)'
              }}>{`${profile?.position ?? 0}`}</Text>
            </div>
          </div>
        </div>
      </div>

      <div style={{flexGrow: 1, overflow: 'auto'}}>
        <div style={{display: 'flex', flexDirection: "column", maxHeight: '100%', overflowY: 'auto'}}>
          <div className="create-textarea-wrapper">
            <Textarea
              autoFocus={true}
              placeholder='Share something...'
              value={text}
              onChange={handleTextChange}
            />
          </div>

          {!images.length && <div style={{display: "flex", gap: 6, padding: "12px 16px", position: "relative"}}>
            <IconButton
              mode="bezeled"
              size="s"
              style={{alignItems: "center", justifyContent: "center", height: 40, width: 40}}
              onClick={handleIconMediaClick}
            >
              <IconMedia/>
            </IconButton>

            <div style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: 0,
              height: 0,
              opacity: 0,
              overflow: "hidden",
            }}>
              <input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                multiple={false}
                onChange={handleFileChange}
              />
            </div>
          </div>}

          {images.length > 0 && <Reorder.Group
            as={"div"}
            layoutScroll
            values={images}
            onReorder={setImages}
            className="uploaded-images"
          >
            <AnimatePresence>
              {images.map((image, index) => (
                <Reorder.Item
                  as={"div"}
                  key={image.id}
                  value={image}
                  drag={false}
                  className="upload-image-wrapper"
                  initial={{opacity: 0}}
                  animate={{opacity: 1}}
                  exit={{opacity: 0}}
                >
                  <img className="upload-image" src={image.url} alt={`${index + 1}`}/>
                  {image.isUploading && (
                    <div className="upload-image-overlay" style={{
                      backgroundColor: "rgba(255, 255, 255, 0.5)",
                      backdropFilter: "blur(4px)"
                    }}>
                      <Spinner size={"l"}/>
                    </div>
                  )}
                  <div className="upload-image-overlay">
                    <IconButton className="upload-image__close" size={"s"} mode={"outline"}
                                onClick={() => deleteImage(index)}>
                      <Icon24Close/>
                    </IconButton>
                  </div>
                </Reorder.Item>
              ))}
            </AnimatePresence>
          </Reorder.Group>}

          {repostId &&
            <div className="reposted-post" style={{margin: '8px 16px 0 16px'}}>
              {!repostedPost && <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{width: 36, height: 36}}><Spinner size={'m'}/></div>
              </div>}
              {repostedPost && <FeedItem item={repostedPost} readonly={true}/>}
            </div>}
        </div>
      </div>

      <div style={{padding: '16px'}}>
        <Button disabled={(!text && !images.length) || isUploadingPost} size="s" stretched={true}
                loading={isUploadingPost} onClick={handlePost}>Post</Button>
      </div>
    </div>
  );
};

export default Create;
