// Mock data for a single user
import {FeedResponse, LeaderboardResponse, Post, Task, User} from "../types";

export const mockPost: Post = {
  id: 123,
  author: {
    id: 1,
    user_name: 'johndoe',
    first_name: 'John',
    last_name: 'Doe',
    created: '2023-01-01T12:00:00Z',
    updated: '2023-01-02T12:00:00Z',
    is_follow: false,
    followers: 150,
    following: 100,
    boosters: 50,
    invited: 2,
    avatar: 'https://example.com/avatar.jpg',
    balance: 250.0,
    position: 1,
  },
  text: 'This is a mock post for testing purposes.',
  media: 'https://pbs.twimg.com/media/Emq7NwoVkAI-Pbc.jpg',
  views: 100,
  likes: 25,
  is_liked: true,
  is_viewed: true,
  created: '2024-09-23T14:00:00Z',
};

const mockRepostedPost = {...mockPost, id: 122, media: 'https://pbs.twimg.com/media/E3sIrQMVUAMElPF.jpg', text: 'Mock of reposted post'};

export const mockUser: User = {
  id: 1,
  user_name: 'johndoe',
  first_name: 'John',
  last_name: 'Doe',
  created: '2023-01-01T12:00:00Z',
  updated: '2023-01-02T12:00:00Z',
  is_follow: true,
  followers: 150,
  following: 100,
  boosters: 50,
  invited: 2,
  avatar: 'https://example.com/avatar.jpg',
  balance: 250.0,
  position: 1,
  posts: [{...mockPost, repost: mockRepostedPost}, mockRepostedPost]
};

// Mock data for FeedResponse with 3 posts
export const mockFeedResponse: FeedResponse = {
  cursor: '123',
  posts: [
    {...mockPost, repost: mockRepostedPost},
    mockRepostedPost,
    {
      id: 121,
      author: {
        id: 2,
        user_name: 'jane_smith',
        first_name: 'Jane',
        last_name: 'Smith',
        created: '2024-09-02T13:00:00Z',
        updated: '2024-09-11T13:00:00Z',
        is_follow: true,
        followers: 250,
        following: 150,
        boosters: 5,
        invited: 1,
        balance: 150,
        position: 2,
        posts: [],
      },
      text: 'Here is another interesting post.',
      views: 200,
      likes: 0,
      is_liked: false,
      is_viewed: true,
      created: '2024-09-22T14:00:00Z',
    },
    {
      id: 120,
      author: {
        id: 3,
        user_name: 'alice_wonder',
        first_name: 'Alice',
        last_name: 'Wonder',
        created: '2024-09-03T14:00:00Z',
        updated: '2024-09-12T14:00:00Z',
        is_follow: true,
        followers: 300,
        following: 100,
        boosters: 0,
        invited: 0,
        balance: 200,
        position: 3,
        posts: [],
      },
      text: 'The third post is here!',
      views: 300,
      likes: 50,
      is_liked: true,
      is_viewed: false,
      created: '2024-09-23T15:00:00Z',
    },
    ...Array(20).fill(null).map((_, index) => ({ ...mockPost, id: 120 - 1 - index, text: mockPost.text + ` (${(120 - 1 - index)})`, media: undefined })),
  ],
};

export const mockImages = [
  'https://jollycontrarian.com/images/6/6c/Rickroll.jpg',
  'https://i.ytimg.com/vi/xbXoWRs2C8M/sddefault.jpg',
  'https://i.ytimg.com/vi/xvFZjo5PgG0/mqdefault.jpg',
  'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHaD838XiDfv1VMDyFufZLn4DMbUMIQou5J34chtHPfBs4Ngj1eNZ2Hlq1QfKlGbINF3c&usqp=CAU',
  'https://steamuserimages-a.akamaihd.net/ugc/775111879946189452/6ECB32C6C2CE523D739E208D64146F2DDC4870EC/?imw=512&&ima=fit&impolicy=Letterbox&imcolor=%23000000&letterbox=false',
];

export const mockLeaderboardResponse: LeaderboardResponse = {
  profiles: [
    JSON.parse(JSON.stringify(mockUser)),
    JSON.parse(JSON.stringify({...mockUser, id: 2, position: 2, balance: 236, first_name: 'Jane', last_name: 'Smith', user_name: 'janesmith'})),
    JSON.parse(JSON.stringify({...mockUser, id: 3, position: 3, balance: 177, first_name: 'Bob', last_name: 'Johnson', user_name: 'bobjohnson'})),
    JSON.parse(JSON.stringify({...mockUser, id: 4, position: 4, balance: 39, first_name: 'Alex', last_name: 'Johnson', user_name: 'alexjohnson'}))
  ],
  me: JSON.parse(JSON.stringify(mockUser))
}

export const mockTasks: Task[] = [
  {
    id: 1,
    title: 'Task 1',
    description: 'Description for Task 1',
    reward: 300,
    is_claimable: true,
    is_done: true,
  },
  {
    id: 2,
    title: 'Task 2',
    description: 'Description for Task 2',
    reward: 420,
    is_claimable: false,
    is_done: true,
  },
  {
    id: 3,
    title: 'Task 3',
    description: 'Description for Task 3',
    reward: 1337,
    is_claimable: true,
    is_done: false,
  },
  {
    id: 4,
    title: 'Task 4',
    description: 'Description for Task 4',
    reward: 228,
    is_claimable: false,
    is_done: false,
  }
];
