import { AppDispatch } from "../../../app/store";
import { RootState } from "../../../app/store";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Post, MediaUploadResponse } from "../../../api/types";
import {createPostData, createRepostData, upload} from "../services/createService";

interface CreateState {
    text: string;
    media?: string;
    maxLength: number;
}

const initialState: CreateState = {
    text: '',
    media: undefined,
    maxLength: 420,
};

interface CreatePostArgs {
    text: string;
    media?: string;
}

export const createPost = createAsyncThunk<Post, CreatePostArgs, { dispatch: AppDispatch; state: RootState }>(
    'create/createPost',
    async ({ text, media }, { dispatch }) => {
    const data = await createPostData(text, media);
    return data;
    }
);

interface CreateRepostArgs extends CreatePostArgs {
  repostId: string
}

export const createRepost = createAsyncThunk<Post, CreateRepostArgs, { dispatch: AppDispatch; state: RootState }>(
  'create/createPost',
  async ({ repostId, text, media }, { dispatch }) => {
    const data = await createRepostData(repostId, text, media);
    return data;
  }
);

interface GetUploadArgs {
  file: File;
}


export const uploadFile = createAsyncThunk<MediaUploadResponse, GetUploadArgs>(
  'feed/upload', async (args) => {
    const file = args.file;

    if (file && file.type.startsWith('image/')) {
      const data = await upload(file);
      return data
    } else {
      throw new Error('Invalid file type');
    }
  }
);

const slice = createSlice({
    name: 'create',
    initialState,
    reducers: {
      setInputText(state, action: PayloadAction<string>) {
        if (action.payload.length <= state.maxLength) {
          state.text = action.payload;
        }
      },
      clearCreateForm(state) {
        state.text = '';
        state.media = undefined;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(uploadFile.fulfilled, (state, action: PayloadAction<MediaUploadResponse>) => {
          state.media = action.payload.object_name;
        })
        .addCase(createPost.fulfilled, (state, action: PayloadAction<Post>) => {
        //   state.feeds.all.items.unshift(action.payload);
          state.text = '';
        })
    },
  });

  export const { setInputText, clearCreateForm } = slice.actions;

  export default slice.reducer;
