import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {RootState} from "../../../app/store";
import {useEffect} from "react";
import {claimTask, fetchTasks} from "../redux/tasksSlice";

export const useTasksData = () => {
  const dispatch = useAppDispatch();

  const loading = useAppSelector((state: RootState) => state.tasks.loading);
  const loaded = useAppSelector((state: RootState) => state.tasks.loaded);
  const error = useAppSelector((state: RootState) => state.tasks.error);
  const tasks = useAppSelector((state: RootState) => state.tasks.items);

  useEffect(() => {
    if (!loaded) {
      dispatch(fetchTasks());
    }
  }, [dispatch, loaded]);

  const handleTaskClaim = async (taskId: number) => {
    await dispatch(claimTask(taskId));
    await dispatch(fetchTasks());
  }

  return {
    loading,
    loaded,
    error,
    tasks,
    handleTaskClaim
  }
}
