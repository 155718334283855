import {LeaderboardResponse, ProfilesResponse} from '../../../api/types';
import {fetchLeaderboard, searchProfiles} from '../../../api/userApi';
import {mockMode} from "../../../config";
import {mockLeaderboardResponse} from "../../../api/mocks/mocks";

export const fetchLeaderboardData = async (): Promise<LeaderboardResponse> => {
  if (mockMode) {
    return new Promise((resolve) =>
      setTimeout(
        () =>
          resolve(mockLeaderboardResponse),
        1000
      )
    );
  } else {
    const response = await fetchLeaderboard();
    return response.data;
  }
};

export const search = async (term: string): Promise<ProfilesResponse> => {
  if (mockMode) {
    return new Promise((resolve) =>
      setTimeout(
        () =>
          resolve({profiles: mockLeaderboardResponse.profiles.slice().filter((profile) => {
            const searchBetween = [profile.user_name, profile.first_name, profile.last_name];
            return searchBetween.some((value) => value.toLowerCase().includes(term.toLowerCase()));
            })}),
        1000
      )
    );
  } else {
    const response = await searchProfiles(term);
    return response.data;
  }
}
