import React from 'react';
import {User} from '../../../api/types';
import {formatter} from '../../../app/helpers';
import {navigateWithinTab} from "../../navigation/stack/redux/navigationStackSlice";
import './LeaderboardItem.css';
import {Avatar, Text} from '@telegram-apps/telegram-ui';
import {useAppDispatch} from "../../../app/hooks";
import {useNavigate} from "react-router-dom";
import '../../../app/ui/assets/UserListItem.css';
import {IconDot} from "../../../app/ui/components/IconDot";

interface LeaderboardItemProps {
  item: User;
  topBalance: number;
}

const LeaderboardItem: React.FC<LeaderboardItemProps> = ({item, topBalance}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleClick = () => {
    dispatch(navigateWithinTab('top', `/top/user-profile/${item.id}`, navigate));
  };

  return (
    <div className="leaderboard-item user-list-item"
         style={{'--progress': `${(item.balance / topBalance) * 100}%`} as any}
         onClick={handleClick}
    >
      <Avatar className="user-list-item__avatar" src={item.avatar} size={40}/>

      <div className="user-list-item__content">
        <Text weight={'2'}
              style={{fontSize: 16, lineHeight: '21px'}}>{`${item.first_name} ${item.last_name}`}</Text>
        <div style={{display: 'flex', alignItems: 'center', gap: '4px'}}>
          <IconDot color={'green'} />
          <Text weight={'1'} style={{
            color: 'var(--tgui--hint_color)',
            fontSize: 15,
            lineHeight: '20px'
          }}>{`${formatter.format(item.balance)}`}</Text>
        </div>
      </div>

      <div className="user-list-item__append">
        <div style={{
          display: 'flex',
          minWidth: 36,
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          {item.position === 1 ? (
            <Text style={{fontSize: '24px'}}>🥇</Text>
          ) : item.position === 2 ? (
            <Text style={{fontSize: '24px'}}>🥈</Text>
          ) : item.position === 3 ? (
            <Text style={{fontSize: '24px'}}>🥉</Text>
          ) : (
            <Text weight={'1'} style={{
              fontSize: '14px',
              height: 24,
              minWidth: 24,
              borderRadius: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: 'var(--tgui--secondary_fill)'
            }}>{`${item.position}`}</Text>
          )}
        </div>
      </div>
    </div>
  );
};

export default LeaderboardItem;
