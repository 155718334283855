import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const tabNames = ['feed', 'top', 'tasks', 'profile'];

interface TabNavigationState {
  feed: string;
  top: string;
  tasks: string;
  profile: string;
}

const initialState: TabNavigationState = {
  feed: '/feed',
  top: '/top',
  tasks: '/tasks',
  profile: '/profile',
};

const tabNavigationSlice = createSlice({
  name: 'tabNavigation',
  initialState,
  reducers: {
    setTabRoute: (state, action: PayloadAction<{ tab: keyof TabNavigationState; route: string }>) => {
      state[action.payload.tab] = action.payload.route;
    },
  },
});

export const { setTabRoute } = tabNavigationSlice.actions;
export default tabNavigationSlice.reducer;
