import {Button, Modal, Placeholder, Text} from "@telegram-apps/telegram-ui";
import React from "react";
import Diamond from "../assets/Diamond.json";
import Trophy from "../assets/Trophy.json";
import Lightning from "../assets/Lightning.json";
import StarEyes from "../assets/StarEyes.json";
import BrofistLeft from "../assets/BrofistLeft.json";
import BrofistRight from "../assets/BrofistRight.json";
import MoneyBag from "../../tasks/assets/MoneyBag.json";
import {LazyLottie} from "../../../app/ui/components/LazyLottie";
import {
  ModalHeader
} from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader";
import {ModalClose} from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalClose/ModalClose";
import {Icon28Close} from "@telegram-apps/telegram-ui/dist/icons/28/close";

const LeaderboardHowItWorks = () => {
  return (
    <Modal
      dismissible
      header={<ModalHeader
        after={<ModalClose><Icon28Close style={{color: 'var(--tgui--plain_foreground)', cursor: "pointer"}}/></ModalClose>}/>}
      trigger={<Button style={{alignSelf: 'center', background: 'rgba(0, 0, 0, 0.04)', borderRadius: '20px'}}>How It
        Works</Button>}
    >
      <div style={{display: 'flex', flexDirection: 'column', padding: 16}}>
        <Placeholder
          header="How it works"
          description="Global Top leaderboard ranks users based on social activity and boosts from others"
        >
          <LazyLottie animationData={Diamond} size={80}/>
        </Placeholder>
        <Placeholder
          header="Boost Your Position"
          description="Your profile's visibility grows as you climb the leaderboard. The higher your rating, the more popular and discoverable you become"
        >
          <LazyLottie animationData={Trophy} size={80}/>
        </Placeholder>

        <div style={{background: 'var(--tgui--secondary_fill)', marginTop: 32, marginBottom: 32, borderRadius: 12}}>
          <div style={{display: 'flex', justifyContent: 'center', padding: 16}}>
            <Text weight={"2"} style={{fontSize: 20, lineHeight: "26px"}}>How to Climb the Ranks</Text>
          </div>

          <div style={{display: 'flex', justifyContent: 'center', gap: 8}}>
            <Placeholder
              header="Complete Tasks"
              description="Participate in platform activities to earn points and improve your ranking"
              style={{padding: 16}}
            >
              <LazyLottie animationData={MoneyBag} size={80}/>
            </Placeholder>
          </div>
          <div style={{display: 'flex', justifyContent: 'center', gap: 8}}>
            <Placeholder
              header="Boost Yourself"
              description="You can also boost your own profile for a quicker rise in the rankings"
              style={{padding: 16}}
            >
              <LazyLottie animationData={Lightning} size={80}/>
            </Placeholder>
            <Placeholder
              header="Receive Boosts"
              description="Other users can boost your profile, helping you move up the leaderboard"
              style={{padding: 16}}
            >
              <div style={{display: "flex", alignItems: 'center', width: 80, height: 80}}>
                <LazyLottie animationData={BrofistLeft} size={40}/>
                <LazyLottie animationData={BrofistRight} size={40}/>
              </div>
            </Placeholder>
          </div>
        </div>

        <Placeholder
          description="The more boosts and ratings you receive, the faster you'll increase your popularity and ranking"
        >
          <LazyLottie animationData={StarEyes} size={80}/>
        </Placeholder>
      </div>
    </Modal>
  );
};

export default LeaderboardHowItWorks;
