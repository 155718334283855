import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../../../../app/store';
import { setTabRoute } from '../../tabBar/redux/tabsSlice';
import { NavigateFunction } from 'react-router-dom';

export interface NavigationStackState {
  feed: string[];
  top: string[];
  tasks: string[];
  profile: string[];
}

const initialState: NavigationStackState = {
  feed: ['/feed'],
  top: ['/top'],
  tasks: ['/tasks'],
  profile: ['/profile'],
};

export const determineCurrentTab = (path: string): keyof NavigationStackState | null => {
  if (path.startsWith('/feed')) return 'feed';
  if (path.startsWith('/top')) return 'top';
  if (path.startsWith('/tasks')) return 'tasks';
  if (path.startsWith('/profile')) return 'profile';
  return null;
};

const navigationSlice = createSlice({
  name: 'navigationStack',
  initialState,
  reducers: {
    push: (state, action: PayloadAction<{ tab: keyof NavigationStackState; route: string }>) => {
      state[`${action.payload.tab}`].push(action.payload.route);
    },
    pop: (state, action: PayloadAction<{ tab: keyof NavigationStackState }>) => {
      const stack = state[`${action.payload.tab}`];
      if (stack.length > 1) {
        stack.pop();
      }
    },
    reset: (state, action: PayloadAction<{ tab: keyof NavigationStackState }>) => {
      state[`${action.payload.tab}`] = [state[`${action.payload.tab}`][0]]; // Reset to initial route
    },
  },
});

export const { push, pop, reset } = navigationSlice.actions;
export default navigationSlice.reducer;

// Thunk action to handle navigation within a tab and update both slices
export const navigateWithinTab = (tab: keyof NavigationStackState, route: string, navigate: NavigateFunction) => (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  const currentRoute = getState().tabs[tab];
  if (currentRoute !== route) {
    dispatch(push({ tab, route }));
    dispatch(setTabRoute({ tab, route }));
    navigate(route);
  }
};

// Thunk action to handle switching tabs and resetting the stack if necessary
export const switchTab = (tab: keyof NavigationStackState, navigate: NavigateFunction) => (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  const currentStack = getState().navigationStack[tab];
  const currentRoute = currentStack[currentStack.length - 1];

  dispatch(setTabRoute({ tab, route: currentRoute }));
  navigate(currentRoute);
};

export const resetTab = (tab: keyof NavigationStackState, navigate: NavigateFunction) => (dispatch: AppDispatch) => {
  dispatch(reset({ tab }));

  dispatch(switchTab(tab, navigate));
}


interface GoBackInTabArgs {
  tab: keyof NavigationStackState;
  navigate: NavigateFunction;
}

export const goBackInTab = createAsyncThunk<void, GoBackInTabArgs, { dispatch: AppDispatch; state: RootState }>(
  'navigation/goBackInTab',
  async ({ tab, navigate }, { dispatch, getState }) => {
    const stack = getState().navigationStack[tab];
    if (stack.length > 1) {
      dispatch(pop({ tab }));
      const previousRoute = stack[stack.length - 2]; // Get the new top of the stack
      dispatch(setTabRoute({ tab, route: previousRoute }));
      navigate(previousRoute);
    }
  }
);
