import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useProfileData} from '../hooks/useProfileData';

import {useAppDispatch} from '../../../app/hooks';
import {useNavigate} from 'react-router-dom';
import {navigateWithinTab} from '../../navigation/stack/redux/navigationStackSlice';

import {Avatar, Button, Divider, Skeleton, Spinner, Text} from '@telegram-apps/telegram-ui';
import {IconPencil} from '../assets/IconPencil';
import {IconPlus} from '../assets/IconPlus';
import {IconChevron} from '../assets/IconChevron';

import ProfilePosition from './ProfilePosition';
import ProfilePostsEmpty from './ProfilePostsEmpty';
import FeedItem from '../../feed/components/FeedItem';

import {formatter} from '../../../app/helpers';
import {clearCreateForm} from '../../create/redux/creationSlice';
import UserListModal from "../../../app/ui/components/UserListModal";

interface ProfileProps {
  userId: string | null;
}

const Profile: React.FC<ProfileProps> = ({userId}) => {
  const {
    profile,
    isSelfProfile,
    postsLoading,
    loading,
    error,

    loadingFollowers,
    loadingFollowing,
    loadingBoosters,

    followers,
    following,
    boosters,

    handleFollow,
    handleUnfollow,
    handleFetchFollowers,
    handleFetchFollowing,
    handleFetchBoosters,
    handleFetchMorePosts
  } = useProfileData(userId);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isFollowersModalOpen, setIsFollowersModalOpen] = useState(false);
  const [isFollowingModalOpen, setIsFollowingModalOpen] = useState(false);
  const [isBoostersModalOpen, setIsBoostersModalOpen] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(() => {
    const container = containerRef.current;
    if (container) {
      const scrollTop = container.scrollTop;
      const scrollHeight = container.scrollHeight;
      const clientHeight = container.clientHeight;

      const remainingScroll = scrollHeight - scrollTop - clientHeight;

      if (remainingScroll < 200 && !loading) {
        handleFetchMorePosts();
      }
    }
  }, [loading, handleFetchMorePosts]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  const handleCreateTap = () => {
    dispatch(clearCreateForm());
    dispatch(navigateWithinTab('profile', `/profile/create`, navigate));
  };

  const handleFollowUnfollowTap = () => {
    if (profile?.is_follow) {
      handleUnfollow(profile?.id ?? 0);
    } else {
      handleFollow(profile?.id ?? 0);
    }
  };

  const toggleFollowersModal = async (value: boolean) => {
    if (value) {
      handleFetchFollowers(profile?.id ?? 0);
    } else {
      setIsFollowersModalOpen(value);
    }
  };

  const toggleFollowingModal = async (value: boolean) => {
    if (value) {
      handleFetchFollowing(profile?.id ?? 0);
    } else {
      setIsFollowingModalOpen(value);
    }
  };

  const toggleBoostersModal = async (value: boolean) => {
    if (value) {
      handleFetchBoosters(profile?.id ?? 0);
    } else {
      setIsBoostersModalOpen(value);
    }
  };

  return (
    <div ref={containerRef} className="container"
         style={{display: 'flex', flexDirection: 'column', padding: 0, overflowY: 'scroll', height: '100dvh'}}
    >
      <div style={{display: 'flex', flexDirection: 'column', gap: '16px', padding: 16}}>
        {error && <Text>Error: {error}</Text>}

        <div style={{display: 'flex', flexDirection: 'column', gap: '6px', alignItems: 'center'}}>
          {!loading ? <Avatar size={96} src={profile?.avatar}/> :
            <Spinner className="spinner-xl" size={'l'}/>}
          {!loading
            ? <Text weight="1"
                    style={{
                      fontSize: "24px",
                      lineHeight: "28px"
                    }}>{`${profile?.first_name} ${profile?.last_name}`}</Text>
            : <div style={{display: 'flex', alignItems: 'center', gap: 4, overflow: "hidden"}}>
              <Skeleton className="skeleton" visible style={{borderRadius: 12, width: 60, height: 26, marginTop: 2}}/>
              <Skeleton className="skeleton" visible style={{borderRadius: 12, width: 60, height: 26, marginTop: 2}}/>
            </div>}
        </div>

        <ProfilePosition type="profile" user={profile} loading={loading}/>

        <div style={{display: 'flex', flexDirection: 'row', gap: '24px', justifyContent: 'center'}}>
          {!loading ? (<>
              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer'}}
                   onClick={() => setIsFollowingModalOpen(true)}>
                <Text weight="2"
                      style={{
                        fontSize: "17px",
                        lineHeight: "22px"
                      }}>{formatter.format(profile?.following ?? 0)}</Text>
                <Text weight='3' style={{
                  fontSize: '13px',
                  lineHeight: '16px'
                }}>following</Text>
              </div>
              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer'}}
                   onClick={() => setIsFollowersModalOpen(true)}>
                <Text weight="2"
                      style={{
                        fontSize: "17px",
                        lineHeight: "22px"
                      }}>{formatter.format(profile?.followers ?? 0)}</Text>
                <Text weight='3' style={{
                  fontSize: '13px',
                  lineHeight: '16px'
                }}>followers</Text>
              </div>
              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer'}}
                   onClick={() => setIsBoostersModalOpen(true)}>
                <Text weight="2"
                      style={{fontSize: "17px", lineHeight: "22px"}}>{formatter.format(profile?.boosters ?? 0)}</Text>
                <Text weight='3' style={{
                  fontSize: '13px',
                  lineHeight: '16px'
                }}>boosters</Text>
              </div>
              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer'}}
                   onClick={() => setIsBoostersModalOpen(true)}>
                <Text weight="2"
                      style={{fontSize: "17px", lineHeight: "22px"}}>{formatter.format(profile?.invited ?? 0)}</Text>
                <Text weight='3' style={{
                  fontSize: '13px',
                  lineHeight: '16px'
                }}>invited</Text>
              </div>
            </>) :
            (Array(4).fill(null).map((_, index) => (
              <Skeleton className="skeleton" visible key={index} style={{width: 52, height: 38, borderRadius: 8}}/>)))
          }
        </div>

        {loading ? (
          <Button size="s" mode="bezeled" stretched={true} disabled/>
        ) : isSelfProfile ? (
          <Button onClick={handleCreateTap} before={<IconPencil/>} size="s" mode="bezeled" stretched={true}>
            Post
          </Button>
        ) : profile?.is_follow ? (
          <Button onClick={handleFollowUnfollowTap} before={<IconChevron/>} size="s" mode="gray" stretched={true}>
            Unfollow
          </Button>
        ) : (
          <Button onClick={handleFollowUnfollowTap} before={<IconPlus/>} size="s" mode="filled" stretched={true}>
            Follow
          </Button>
        )}
      </div>

      <Divider/>

      {(profile?.posts?.length ?? 0) === 0 && !postsLoading && (
        <ProfilePostsEmpty name={profile?.first_name ?? ''}/>)}

      {(profile?.posts?.length ?? 0) > 0 && (
        <>
          <div style={{
            paddingLeft: '16px',
            paddingRight: '16px',
            paddingTop: '12px',
            paddingBottom: '12px',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px'
          }}>
            {profile?.posts?.map((item, index) => {
                const updatedItem = {...item, author: profile};
                return (<FeedItem key={updatedItem.id} item={updatedItem}
                                  divider={index < (profile?.posts?.length ?? 0) - 1}/>)
              }
            )}
          </div>
        </>
      )}

      {postsLoading && (
        <>
          <div style={{display: 'flex', justifyContent: 'center', padding: '20px'}}>
            <Spinner size='m'/>
          </div>
        </>
      )}

      <UserListModal
        open={isFollowersModalOpen}
        onOpenChange={toggleFollowersModal}
        users={followers}
        title="Followers"
        after={''}
        loading={loadingFollowers}
      />

      <UserListModal
        open={isFollowingModalOpen}
        onOpenChange={toggleFollowingModal}
        users={following}
        title="Following"
        after={''}
        loading={loadingFollowing}
      />

      <UserListModal
        open={isBoostersModalOpen}
        onOpenChange={toggleBoostersModal}
        users={boosters}
        title="Boosters"
        after={''}
        loading={loadingBoosters}
      />
    </div>
  );
};

export default Profile;
