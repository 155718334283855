import Lottie, {LottieRefCurrentProps} from "lottie-react";
import React, {useEffect} from "react";
import {useInView} from "framer-motion";

interface LazyLottieProps {
  animationData: unknown,
  size: number
}

export const LazyLottie:React.FC<LazyLottieProps> = ({animationData, size}) => {
  const container = React.useRef(null);
  const lottieRef = React.useRef<LottieRefCurrentProps | null>(null);
  const inView = useInView(container);

  useEffect(() => {
    if (inView) {
      lottieRef.current?.play();
    } else {
      lottieRef.current?.stop();
    }
  }, [inView]);

  return (
    <div ref={container}>
      <Lottie autoplay={false} lottieRef={lottieRef} loop={true} animationData={animationData}
                 style={{width: size, height: size}}/>
    </div>
  );
};
