import {FeedResponse, ProfilesResponse, User} from "../../../api/types";
import {
  fetchProfile,
  fetchUserBoosters,
  fetchUserFollowers,
  fetchUserFollowing,
  fetchUserPosts,
  followUser,
  unfollowUser
} from "../../../api/userApi";
import {mockMode} from "../../../config";
import {mockUser} from "../../../api/mocks/mocks";

export const fetchProfileData = async (userId: string | null): Promise<User> => {
  if (mockMode) {
    return new Promise((resolve) =>
      setTimeout(() => resolve(JSON.parse(JSON.stringify(mockUser))), 1000)
    );
  } else {
    const response = await fetchProfile(userId);
    return response.data;
  }
};

let mockIdAdder = 0;

export const posts = async (userId: number, cursor?: string): Promise<FeedResponse> => {
  if (mockMode) {
    return new Promise((resolve) =>
      setTimeout(() => {
        if (cursor) {
          mockIdAdder += 100;
          resolve({
            posts: JSON.parse(JSON.stringify(mockUser.posts?.map((post) => ({
                ...post,
                id: post.id + mockIdAdder,
                text: post.text + ' ' + (post.id + mockIdAdder),
              })
            ))),
            cursor: (122 - mockIdAdder).toString()
          })
        } else {
          resolve({
            posts: JSON.parse(JSON.stringify(mockUser.posts)),
            cursor: (122).toString()
          })
        }
      }, 1000)
    );
  } else {
    const response = await fetchUserPosts(userId, cursor);
    return response.data;
  }
};

export const follow = async (userId: number): Promise<void> => {
  const response = await followUser(userId);
  return response.data;
};

export const unfollow = async (userId: number): Promise<void> => {
  const response = await unfollowUser(userId);
  return response.data;
};

export const boosters = async (userId: number): Promise<ProfilesResponse> => {
  if (mockMode) {
    return new Promise((resolve) =>
      setTimeout(() => resolve({
        profiles: [mockUser, {...mockUser, id: 2}, {...mockUser, id: 3}],
      }), 1000)
    );
  } else {
    const response = await fetchUserBoosters(userId);
    return response.data;
  }
};

export const following = async (userId: number): Promise<ProfilesResponse> => {
  if (mockMode) {
    return new Promise((resolve) =>
      setTimeout(() => resolve({
        profiles: [mockUser, {...mockUser, id: 2}, {...mockUser, id: 3}],
      }), 1000)
    );
  } else {
    const response = await fetchUserFollowing(userId);
    return response.data;
  }
};

export const followers = async (userId: number): Promise<ProfilesResponse> => {
  if (mockMode) {
    return new Promise((resolve) =>
      setTimeout(() => resolve({
        profiles: [mockUser, {...mockUser, id: 2}, {...mockUser, id: 3}],
      }), 1000)
    );
  } else {
    const response = await fetchUserFollowers(userId);
    return response.data;
  }
};
