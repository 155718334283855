import React from 'react';
import { useParams } from 'react-router-dom';
import Profile from '../../profile/components/Profile';

interface UserProfileParams {
    userId: string;
    [key: string]: string | undefined;
}

const UserProfile: React.FC = () => {
    const { userId } = useParams<UserProfileParams>();

    return (
        <div>
            <Profile userId={userId ?? null} />
        </div>
    );
}

export default UserProfile;
