import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import TabBar from './features/navigation/tabBar/components/TabBar';
import './App.css';

import Feed from './features/feed/components/Feed';
import Leaderboard from './features/leaderboard/components/Leaderboard';
import Profile from './features/profile/components/Profile';
import Tasks from './features/tasks/components/Tasks';

import UserProfile from './features/profile/components/UserProfile';
import Create from './features/create/components/Create';

import { AppRoot } from '@telegram-apps/telegram-ui';
import { AnimatePresence, motion } from 'framer-motion';

function App() {

  const FeedStack: React.FC = () => (
    <Routes>
      <Route path="/" element={<Feed />} />
      <Route path="/user-profile/:userId" element={
        <motion.div
          initial={{ x: '100%', opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: '-100%', opacity: 0 }}
          transition={{ type: 'ease', stiffness: 300, damping: 30 }}
        >
          <UserProfile />
        </motion.div>
      } />
      <Route path="/create" element={
        <motion.div
          initial={{ x: '100%', opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: '-100%', opacity: 0 }}
          transition={{ type: 'ease', stiffness: 300, damping: 30 }}
        >
          <Create />
        </motion.div>
      } />
      <Route path="/create/:repostId" element={
        <motion.div
          initial={{ x: '100%', opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: '-100%', opacity: 0 }}
          transition={{ type: 'ease', stiffness: 300, damping: 30 }}
        >
          <Create />
        </motion.div>
      } />
    </Routes>
  );

  const LeaderboardStack: React.FC = () => (
    <Routes>
      <Route path="/" element={<Leaderboard />} />
      <Route path="/user-profile/:userId" element={
        <motion.div
          initial={{ x: '100%', opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: '-100%', opacity: 0 }}
          transition={{ type: 'ease', stiffness: 300, damping: 30 }}
        >
          <UserProfile />
        </motion.div>
      } />
    </Routes>
  );

  const TasksStack: React.FC = () => (
    <Routes>
      <Route path="/" element={<Tasks />} />
    </Routes>
  );

  const ProfileStack: React.FC = () => (
    <Routes>
      <Route path="/" element={<Profile userId={null} />} />
      <Route path="/create" element={
        <motion.div
          initial={{ x: '100%', opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: '-100%', opacity: 0 }}
          transition={{ type: 'ease', stiffness: 300, damping: 30 }}
        >
          <Create />
        </motion.div>
      }/>
      <Route path="/user-profile/:userId" element={
        <motion.div
          initial={{ x: '100%', opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: '-100%', opacity: 0 }}
          transition={{ type: 'ease', stiffness: 300, damping: 30 }}
        >
          <UserProfile />
        </motion.div>
      } />
    </Routes>
  );

  return (
    <AppRoot>
      <Router>
        <AnimatePresence mode="wait">
          <Routes key="routes">
            <Route path="/feed/*" element={<FeedStack />} />
            <Route path="/top/*" element={<LeaderboardStack />} />
            <Route path="/tasks/*" element={<TasksStack />} />
            <Route path="/profile/*" element={<ProfileStack />} />
            <Route path="/" element={<Navigate to="/feed" />} />
          </Routes>
          <TabBar key="tabs" />
        </AnimatePresence>
      </Router>
    </AppRoot>
  );

}

export default App;
