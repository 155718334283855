import { Post, MediaUploadResponse } from '../../../api/types';
import {postFeed, repostFeed, uploadFile} from '../../../api/userApi';
import {mockMode} from "../../../config";
import {mockImages, mockPost} from "../../../api/mocks/mocks";

export const createPostData = async (text: string, media?: string): Promise<Post> => {
    if (mockMode) {
        return new Promise((resolve) =>
          setTimeout(() => resolve(mockPost), 1000)
        );
    } else {
        const response = await postFeed(text, media);
        return response.data;
    }
}

export const createRepostData = async (repostId: string, text: string, media?: string): Promise<Post> => {
    if (mockMode) {
        return new Promise((resolve) =>
          setTimeout(() => resolve(mockPost), 1000)
        );
    } else {
        const response = await repostFeed(repostId, text, media);
        return response.data;
    }
}



export const upload = async (file: File): Promise<MediaUploadResponse> => {
    if (mockMode) {
        return new Promise((resolve) =>
          setTimeout(() => resolve({
              object_name: mockImages[Math.floor(Math.random() * mockImages.length)],
              content_type: 'image/jpeg'
          }), 5000)
        );
    } else {
        const response = await uploadFile(file);
        return response.data;
    }
}
