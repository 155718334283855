import React from 'react';
import { Text } from '@telegram-apps/telegram-ui';
import Lottie from 'lottie-react';
import ThinkingFace from '../assets/ThinkingFace.json';

interface ProfilePostsEmptyProps {
    name: string;
}

const ProfilePostsEmpty: React.FC<ProfilePostsEmptyProps> = ({ name }) => {
    return (
        <div style={{paddingTop: '24px', paddingBottom: '24px', paddingLeft: '16px', paddingRight: '16px',
        display: 'flex', gap: '8px', flexDirection: 'column', alignItems: 'center'}}>
            <Lottie animationData={ThinkingFace} loop={true} style={{width: '40px', height: '40px'}} />
            <div style={{display: 'flex', gap: '6px', flexDirection: 'column', alignItems: 'center'}}>
                <Text weight='1' style={{fontSize: '24px', lineHeight: '28px'}}>No Posts Yet</Text>
                <Text weight='3' style={{color: 'var(--tgui--hint_color)', textAlign: 'center', fontSize: '13px', lineHeight: '16px'}}>{`${name} hasn\`t posted anything yet! Stay tuned, something cool might be coming soon`}</Text>
            </div>
        </div>
    );
};

export default ProfilePostsEmpty;