import React from 'react';
import { useState } from 'react';
import Lottie from 'lottie-react';

import { getInvoice } from '../services/buyMenuService';
import { Text, Button } from '@telegram-apps/telegram-ui';

import emostar from '../assets/emostar.json';
import { formatter } from '../../../app/helpers';

import WebApp from '@twa-dev/sdk';
import {IconDot} from "../../../app/ui/components/IconDot";

interface BuyMenuProps {
    userId: number;
}

const BuyMenu: React.FC<BuyMenuProps> = (model) => {
    const handleBack = () => {
    };

    const buy = async (amount: number, userId: number) => {
        setIsBuyLoading(true);
        const invoice = await getInvoice(amount, userId);
        setIsBuyLoading(false);
        WebApp.openInvoice(invoice.invoice_link, (event: any) => {
            if (event === 'paid') {
                handleBack();
            }
        });
    };

    const options = [500, 1000, 5000, 10000, 50000];
    const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);
    const [isBuyLoading, setIsBuyLoading] = useState(false);

    const handleOptionTap = (index: number) => {
        setSelectedOptionIndex(index);
    };

    return (
        <div style={{paddingTop: '24px', paddingBottom: '24px', paddingLeft: '16px', paddingRight: '16px',
            display: 'flex', gap: '8px', flexDirection: 'column', alignItems: 'center'}}>
            <Lottie animationData={emostar} loop={true} style={{width: '44px', height: '44px'}} />
            <div style={{display: 'flex', gap: '6px', flexDirection: 'column', alignItems: 'center'}}>
                <Text weight='1' style={{fontSize: '24px', lineHeight: '28px'}}>Boost Profile</Text>
                <Text weight='3' style={{color: 'var(--tgui--hint_color)', textAlign: 'center', fontSize: '13px', lineHeight: '16px'}}>Boost profile by completing tasks or purchasing ratings directly. The higher position on the leaderboard, the more visible profile becomes to other users.</Text>
            </div>
            <div style={{paddingTop: '8px', width: '100%', display: 'flex', gap: '16px', flexDirection: 'column', alignItems: 'center'}}>
                <div style={{width: '100%', display: 'flex', gap: '6px', flexDirection: 'column', alignItems: 'center'}}>
                    {options.map((option, index) => (
                        <Button
                            onClick={() => handleOptionTap(index)}
                            size='m'
                            mode={selectedOptionIndex === index ? 'bezeled' : 'outline'}
                            stretched={true}
                            before={<IconDot color={'green'} />}>
                            {formatter.format(option)}
                        </Button>
                    ))}
                </div>
                <Button loading={isBuyLoading}
                    onClick={() => buy(options[selectedOptionIndex] / 10, model.userId)}
                    size='l'
                    mode='filled'
                    stretched={true}
                >
                    Confirm and pay
                </Button>
            </div>
        </div>
    );
};

export default BuyMenu;
