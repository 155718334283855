import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {boosters, fetchProfileData, follow, followers, following, posts, unfollow} from '../services/profileService';
import {FeedResponse, ProfilesResponse, User} from '../../../api/types';

interface ProfileState {
  currentUser: User | null;
  profiles: { [key: string]: User };
  loading: boolean;
  postsLoading: boolean;
  error: string | null;

  followers: User[];
  following: User[];
  boosters: User[];
  loadingFollowers: boolean;
  loadingFollowing: boolean;
  loadingBoosters: boolean;
}

const initialState: ProfileState = {
  currentUser: null,
  profiles: {},
  loading: true,
  postsLoading: true,
  error: null,

  followers: [],
  following: [],
  boosters: [],
  loadingFollowers: false,
  loadingFollowing: false,
  loadingBoosters: false,
};

export const fetchProfile = createAsyncThunk(
  'profile/fetchProfile',
  async (userId: string | null) => {
    const data = await fetchProfileData(userId);
    return {data, userId};
  }
);

export const fetchPosts = createAsyncThunk(
  'profile/fetchPosts',
  async ({userId, cursor}:{userId: number, cursor?:string}) => {
    const data = await posts(userId, cursor);
    return {data, userId, argCursor: cursor};
  }
);

export const followProfile = createAsyncThunk(
  'profile/follow',
  async (userId: number) => {
    const data = await follow(userId);
    return data;
  }
);

export const unfollowProfile = createAsyncThunk(
  'profile/unfollow',
  async (userId: number) => {
    const data = await unfollow(userId);
    return data;
  }
);

export const fetchFollowers = createAsyncThunk(
  'profile/fetchFollowers',
  async (userId: number) => {
    const response = await followers(userId);
    return response;
  });

export const fetchFollowing = createAsyncThunk(
  'profile/fetchFollowing',
  async (userId: number) => {
    const response = await following(userId);
    return response;
  });

export const fetchBoosters = createAsyncThunk(
  'profile/fetchBoosters',
  async (userId: number) => {
    const response = await boosters(userId);
    return response;
  });

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setCurrentUser(state, action: PayloadAction<User>) {
      state.currentUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPosts.pending, (state) => {
        state.postsLoading = true;
      })
      .addCase(fetchPosts.fulfilled, (state, action: PayloadAction<{ data: FeedResponse; userId: number, argCursor?: string }>) => {
        if (state.profiles[action.payload.userId]) {
          if (state.profiles[action.payload.userId].posts === undefined || typeof action.payload.argCursor !== 'string') {
            state.profiles[action.payload.userId].posts = action.payload.data.posts;
          } else if (action.payload.data.cursor) {
            state.profiles[action.payload.userId].posts = state.profiles[action.payload.userId].posts?.concat(action.payload.data.posts);
          }

          if (action.payload.data.cursor) {
            state.profiles[action.payload.userId].postsCursor = action.payload.data.cursor;
          }
        }

        if (state.currentUser && Number(action.payload.userId) === state.currentUser.id) {
          if (state.currentUser.posts === undefined || typeof action.payload.argCursor !== 'string') {
            state.currentUser.posts = action.payload.data.posts;
          } else if (action.payload.data.cursor) {
            state.currentUser.posts = state.currentUser.posts.concat(action.payload.data.posts);
          }

          if (action.payload.data.cursor) {
            state.currentUser.postsCursor = action.payload.data.cursor;
          }
        }

        state.postsLoading = false;
      })
      .addCase(fetchPosts.rejected, (state, action) => {
        state.postsLoading = false;
      })
      .addCase(fetchProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProfile.fulfilled, (state, action: PayloadAction<{ data: User; userId: string | null }>) => {
        state.loading = false;

        if (action.payload.userId === null) {
          const preloadedPosts = state.currentUser?.posts
          state.currentUser = action.payload.data;
          state.currentUser.posts = preloadedPosts
        } else {
          const preloadedPosts = state.profiles[action.payload.userId]?.posts
          state.profiles[action.payload.userId] = action.payload.data;
          state.profiles[action.payload.userId].posts = preloadedPosts;

          if (Number(action.payload.userId) === state.currentUser?.id) {
            const preloadedPosts = state.currentUser?.posts
            state.currentUser = action.payload.data;
            state.currentUser.posts = preloadedPosts;
          }
        }
      })
      .addCase(fetchProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch profile';
      })
      .addCase(followProfile.pending, (state, action) => {
        const userId = action.meta.arg;
        const profile = state.profiles[userId];
        if (profile) {
          profile.followers += 1;
          profile.is_follow = true;
        }
      })
      .addCase(followProfile.rejected, (state, action) => {
        const userId = action.meta.arg;
        const profile = state.profiles[userId];
        if (profile) {
          profile.followers -= 1;
          profile.is_follow = false;
        }
        state.error = action.error.message || 'Failed to follow';
      })
      .addCase(unfollowProfile.pending, (state, action) => {
        const userId = action.meta.arg;
        const profile = state.profiles[userId];
        if (profile) {
          profile.followers -= 1;
          profile.is_follow = false;
        }
      })
      .addCase(unfollowProfile.rejected, (state, action) => {
        const userId = action.meta.arg;
        const profile = state.profiles[userId];
        if (profile) {
          profile.followers += 1;
          profile.is_follow = true;
        }
        state.error = action.error.message || 'Failed to unfollow';
      })

      .addCase(fetchFollowers.pending, (state) => {
        state.loadingFollowers = true;
      })
      .addCase(fetchFollowers.fulfilled, (state, action: PayloadAction<ProfilesResponse>) => {
        state.loadingFollowers = false;
        state.followers = action.payload.profiles;
      })
      .addCase(fetchFollowers.rejected, (state) => {
        state.loadingFollowers = false;
      })
      .addCase(fetchFollowing.pending, (state) => {
        state.loadingFollowing = true;
      })
      .addCase(fetchFollowing.fulfilled, (state, action: PayloadAction<ProfilesResponse>) => {
        state.loadingFollowing = false;
        state.following = action.payload.profiles;
      })
      .addCase(fetchFollowing.rejected, (state) => {
        state.loadingFollowing = false;
      })
      .addCase(fetchBoosters.pending, (state) => {
        state.loadingBoosters = true;
      })
      .addCase(fetchBoosters.fulfilled, (state, action: PayloadAction<ProfilesResponse>) => {
        state.loadingBoosters = false;
        state.boosters = action.payload.profiles;
      })
      .addCase(fetchBoosters.rejected, (state) => {
        state.loadingBoosters = false;
      })
  },
});

export const {setCurrentUser} = profileSlice.actions;

export default profileSlice.reducer;
