import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {fetchFeedData, like, likes, remove, unlike, view, views} from '../services/feedService';
import {FeedResponse, Post, ProfilesResponse, User} from '../../../api/types';

type FeedType = 'all' | 'top24' | 'top';

const FeedTypeIterable:FeedType[] = ['all', 'top24', 'top'];

interface FeedData {
  items: Post[];
  loaded: boolean;
  cursor: number | null;
}

interface FeedState {
  feeds: {
    [key in FeedType]: FeedData;
  };
  loading: boolean;
  error: string | null;

  postLikes: Record<number, User[]>;
  postViews: Record<number, User[]>;
  loadingLikes: boolean;
  loadingViews: boolean;
}

const initialState: FeedState = {
  feeds: {
    all: {items: [], loaded: false, cursor: null},
    top24: {items: [], loaded: false, cursor: null},
    top: {items: [], loaded: false, cursor: null},
  },
  loading: true,
  error: null,

  postLikes: {},
  postViews: {},
  loadingLikes: false,
  loadingViews: false,

};

export const fetchFeed = createAsyncThunk(
  'feed/fetchFeed',
  async ({ name, cursor }: { name: FeedType; cursor?: number }) => {
    const data = await fetchFeedData(name, cursor);
    return { name, data, argCursor: cursor };
  }
);

export const viewPost = createAsyncThunk(
  'feed/viewPost',
  async (postID: number) => {
    const data = await view(postID);
    return data;
  }
);

export const likePost = createAsyncThunk(
  'feed/likePost',
  async (postID: number) => {
    const data = await like(postID);
    return data;
  }
);

export const unlikePost = createAsyncThunk(
  'feed/unlikePost',
  async (postID: number) => {
    const data = await unlike(postID);
    return data;
  }
);

export const deletePost = createAsyncThunk(
  'feed/deletePost',
  async (postID: number) => {
    const data = await remove(postID);
    return data;
  }
);

export const fetchLikes = createAsyncThunk('feed/fetchLikes', async (postId: number) => {
  const response = await likes(postId);
  return {postId, data: response};
});

export const fetchViews = createAsyncThunk('feed/fetchViews', async (postId: number) => {
  const response = await views(postId);
  return {postId, data: response};
});

const feedSlice = createSlice({
  name: 'feed',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFeed.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchFeed.fulfilled, (state, action: PayloadAction<{ name: FeedType; data: FeedResponse, argCursor?: number }>) => {
        const {name, data} = action.payload;

        if (typeof action.payload.argCursor === 'number') {
          state.feeds[name].items = state.feeds[name].items.concat(data.posts);
        } else {
          state.feeds[name].items = data.posts;
        }

        state.feeds[name].cursor = data.cursor ? parseInt(data.cursor) : null;
        state.feeds[name].loaded = true;
        state.loading = false;
      })
      .addCase(fetchFeed.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch feed';
      })
      .addCase(viewPost.pending, (state, action) => {
        Object.values(state.feeds).forEach(feed => {
          const post = feed.items.find((item) => item.id === action.meta.arg);
          if (post) {
            post.views += 1;
            post.is_viewed = true;
          }
        });
      })
      .addCase(viewPost.rejected, (state, action) => {
        Object.values(state.feeds).forEach(feed => {
          const post = feed.items.find((item) => item.id === action.meta.arg);
          if (post) {
            post.views -= 1;
            post.is_viewed = false;
          }
        });
      })
      .addCase(likePost.pending, (state, action) => {
        Object.values(state.feeds).forEach(feed => {
          const post = feed.items.find((item) => item.id === action.meta.arg);
          if (post) {
            post.likes += 1;
            post.is_liked = true;
          }
        });
      })
      .addCase(likePost.rejected, (state, action) => {
        Object.values(state.feeds).forEach(feed => {
          const post = feed.items.find((item) => item.id === action.meta.arg);
          if (post) {
            post.likes -= 1;
            post.is_liked = false;
          }
        });
      })
      .addCase(unlikePost.pending, (state, action) => {
        Object.values(state.feeds).forEach(feed => {
          const post = feed.items.find((item) => item.id === action.meta.arg);
          if (post) {
            post.likes -= 1;
            post.is_liked = false;
          }
        });
      })
      .addCase(unlikePost.rejected, (state, action) => {
        Object.values(state.feeds).forEach(feed => {
          const post = feed.items.find((item) => item.id === action.meta.arg);
          if (post) {
            post.likes += 1;
            post.is_liked = true;
          }
        });
      })
      .addCase(fetchLikes.pending, (state) => {
        state.loadingLikes = true;
      })
      .addCase(fetchLikes.fulfilled, (state, action: PayloadAction<{ postId: number, data: ProfilesResponse }>) => {
        state.loadingLikes = false;
        state.postLikes[action.payload.postId] = action.payload.data.profiles;

        FeedTypeIterable.forEach((feedType) => {
          const post = state.feeds[feedType].items.find((item) => item.id === action.payload.postId);
          if (post) {
            post.likes = action.payload.data.profiles.length;
          }
        }
        );
      })
      .addCase(fetchLikes.rejected, (state) => {
        state.loadingLikes = false;
      })
      .addCase(fetchViews.pending, (state) => {
        state.loadingViews = true;
      })
      .addCase(fetchViews.fulfilled, (state, action: PayloadAction<{ postId: number, data: ProfilesResponse }>) => {
        state.loadingViews = false;
        state.postViews[action.payload.postId] = action.payload.data.profiles;

        FeedTypeIterable.forEach((feedType) => {
          const post = state.feeds[feedType].items.find((item) => item.id === action.payload.postId);
          if (post) {
            post.views = action.payload.data.profiles.length;
          }
        }
        );
      })
      .addCase(fetchViews.rejected, (state) => {
        state.loadingViews = false;
      })
    ;
  },
});

export default feedSlice.reducer;
