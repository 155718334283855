import React, {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Tabbar} from '@telegram-apps/telegram-ui';
import {IconGlobe} from '../assets/IconGlobe';
import {IconGroup} from "../assets/IconGroup";
import {IconTasks} from "../assets/IconTasks";
import {IconSmile} from "../assets/IconSmile";
import {
  determineCurrentTab,
  goBackInTab,
  NavigationStackState, resetTab,
  switchTab
} from "../../stack/redux/navigationStackSlice";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {useBackButton} from "@telegram-apps/sdk-react";
import {tabNames} from "../redux/tabsSlice";

const TabBar: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const bb = useBackButton();
  const currentTab = determineCurrentTab(location.pathname) ?? 'feed';
  const stack = useAppSelector((state) => state.navigationStack[currentTab]);

  const handleTabClick = (tab: keyof NavigationStackState) => {
    if (currentTab === tab) {
      if (stack && stack.length > 1) {
        dispatch(goBackInTab({tab: currentTab, navigate}));
      } else {
        dispatch(resetTab(tab, navigate));
      }

      dispatch(goBackInTab({tab: currentTab, navigate}));
    } else {
      dispatch(switchTab(tab, navigate));
    }
  };

  const updateTabBarHeight = () => {
    const tabBarHeight = document.querySelector('#tabBar')?.clientHeight || 32;
    document.documentElement.style.setProperty('--tab-bar-height', `${tabBarHeight}px`);
  }

  useEffect(() => {
    updateTabBarHeight();
    window.addEventListener('resize', updateTabBarHeight);
    return () => window.removeEventListener('resize', updateTabBarHeight);
  }, []);

  useEffect(() => {
    const handleBack = () => {
      if (currentTab) {
        dispatch(goBackInTab({tab: currentTab, navigate}));
      }
    };

    if (stack && stack.length > 1) {
      bb.on('click', () => {
        bb.hide();
        handleBack();
      });
      bb.show();
    } else {
      bb.hide();
    }
  }, [stack, currentTab, bb, dispatch, navigate]);

  return (
    <Tabbar id={'tabBar'} style={{paddingBottom: 'var(--safe-area-inset-bottom)'}}>
      {tabNames.map((tab) =>
        <Tabbar.Item
          key={tab}
          text={tab.charAt(0).toUpperCase() + tab.slice(1)}
          selected={tab === determineCurrentTab(location.pathname)}
          onClick={() => handleTabClick(tab as keyof NavigationStackState)}
        >
          {tab === 'feed' && <IconGlobe/>}
          {tab === 'top' && <IconGroup/>}
          {tab === 'tasks' && <IconTasks/>}
          {tab === 'profile' && <IconSmile/>}
        </Tabbar.Item>)}
    </Tabbar>
  );
};

export default TabBar;
