import React from 'react';
import {User} from '../../../api/types';
import {Avatar, Text} from "@telegram-apps/telegram-ui";
import "./LeaderboardTopItem.css";
import ProfilePosition from "../../profile/components/ProfilePosition";

interface LeaderboardTopItemProps {
  item: User;
  bottomPosition: number;
}

const LeaderboardTopItem: React.FC<LeaderboardTopItemProps> = ({item, bottomPosition}) => {
  const betterThanNumUsers = (bottomPosition - item.position) / bottomPosition * 100;

  return (
    <div className="leaderboard-top-item">
      <div className="leaderboard-top-item__top">
        <Avatar className="leaderboard-top-item__avatar" src={item.avatar} size={40}/>
        <div className="leaderboard-top-item__text">
          <Text weight={'2'} style={{fontSize: 16, lineHeight: '21px'}}>{`${item.first_name} ${item.last_name}`}</Text>
          <Text weight={'3'}
                style={{fontSize: 13, lineHeight: '16px'}}>{`You’re better than ${betterThanNumUsers}% of users`}</Text>
        </div>
      </div>
      <ProfilePosition user={item} type="leaderboard"/>
    </div>
  );
};

export default LeaderboardTopItem;
