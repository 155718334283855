import { AxiosResponse } from 'axios';
import { apiClient } from './client';
import {
  User,
  Post,
  InvoiceResponse,
  LeaderboardResponse,
  FeedResponse,
  MediaUploadResponse,
  CreateInvoiceRequest,
  ProfilesResponse, TasksResponse
} from './types';

export const fetchProfile = async (userId: string | null): Promise<AxiosResponse<User>> => {
  const response = await apiClient.get<User>(`/profile${userId ? '/' + userId : ''}`);
  return response;
};

export const fetchUserPosts = async (userId: number, cursor?: string): Promise<AxiosResponse<FeedResponse>> => {
  const response = await apiClient.get<FeedResponse>(`/profile/${userId}/posts${cursor ? `?cursor=${cursor}` : ''}`);
  return response;
};

export const fetchFeed = async (name: string, cursor?: number): Promise<AxiosResponse<FeedResponse>> => {
  const response = await apiClient.get<FeedResponse>(`/feed/${name}${cursor ? `?cursor=${cursor}` : ''}`);
  return response;
};

export const searchProfiles = async (term: string): Promise<AxiosResponse<ProfilesResponse>> => {
  const response = await apiClient.get<ProfilesResponse>('/profile/search', { params: { query: term } });
  return response;
}

export const followUser = async (userID: number): Promise<AxiosResponse<void>> => {
  const response = await apiClient.post<void>(`/profile/${userID}/follow/`);
  return response;
}

export const unfollowUser = async (userID: number): Promise<AxiosResponse<void>> => {
  const response = await apiClient.post<void>(`/profile/${userID}/unfollow/`);
  return response;
}

export const fetchUserBoosters = async (userID: number): Promise<AxiosResponse<ProfilesResponse>> => {
  const response = await apiClient.get<ProfilesResponse>(`/profile/${userID}/boosters`);
  return response;
}

export const fetchUserFollowing = async (userID: number): Promise<AxiosResponse<ProfilesResponse>> => {
  const response = await apiClient.get<ProfilesResponse>(`/profile/${userID}/following`);
  return response;
}

export const fetchUserFollowers = async (userID: number): Promise<AxiosResponse<ProfilesResponse>> => {
  const response = await apiClient.get<ProfilesResponse>(`/profile/${userID}/followers`);
  return response;
}

export const viewPost = async (postID: number): Promise<AxiosResponse<void>> => {
  const response = await apiClient.post<void>(`/posts/${postID}/view`);
  return response;
}

export const likePost = async (postID: number): Promise<AxiosResponse<void>> => {
  const response = await apiClient.post<void>(`/posts/${postID}/like`);
  return response;
}

export const unlikePost = async (postID: number): Promise<AxiosResponse<void>> => {
  const response = await apiClient.post<void>(`/posts/${postID}/unlike`);
  return response;
}

export const deletePost = async (postID: number): Promise<AxiosResponse<void>> => {
  const response = await apiClient.delete<void>(`/posts/${postID}`);
  return response;
}


export const fetchPostViews = async (postID: number): Promise<AxiosResponse<ProfilesResponse>> => {
  const response = await apiClient.get<ProfilesResponse>(`/posts/${postID}/views`);
  return response;
}

export const fetchPostLikes = async (postID: number): Promise<AxiosResponse<ProfilesResponse>> => {
  const response = await apiClient.get<ProfilesResponse>(`/posts/${postID}/likes`);
  return response;
}


export const postFeed = async (text: string, media?: string): Promise<AxiosResponse<Post>> => {
  const response = await apiClient.post<Post>('/posts', { text: text, media: media });
  return response;
}

export const repostFeed = async (repostId: string, text: string, media?: string): Promise<AxiosResponse<Post>> => {
  const response = await apiClient.post<Post>(`/posts/${repostId}/repost`, { text: text, media: media });
  return response;
}

export const uploadFile = async (file: File): Promise<AxiosResponse<MediaUploadResponse>> => {
  const formData = new FormData();
  formData.append('image', file);

  const response = await apiClient.post<MediaUploadResponse>('/upload/image', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response;
}

export const fetchLeaderboard = async (): Promise<AxiosResponse<LeaderboardResponse>> => {
  const response = await apiClient.get<LeaderboardResponse>('/leaderboard');
  return response;
};

export const createInvoice = async (req: CreateInvoiceRequest): Promise<AxiosResponse<InvoiceResponse>> => {
  const response = await apiClient.post<InvoiceResponse>('/create-invoice', { ...req });
  return response;
};

export const fetchTasks = async (): Promise<AxiosResponse<TasksResponse>> => {
  const response = await apiClient.get<TasksResponse>('/tasks');
  return response;
}

export const claimTask = async (taskId: number): Promise<AxiosResponse<void>> => {
  const response = await apiClient.post<void>(`/tasks/${taskId}/mint`);
  return response;
}
