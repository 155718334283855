import {Avatar, Modal, Spinner, Text} from "@telegram-apps/telegram-ui";
import React, {ReactNode} from "react";
import {User} from "../../../api/types";
import {formatter} from "../../helpers";
import {determineCurrentTab, navigateWithinTab} from "../../../features/navigation/stack/redux/navigationStackSlice";
import {useAppDispatch} from "../../hooks";
import {useLocation, useNavigate} from "react-router-dom";
import '../assets/UserListItem.css';
import {IconDot} from "./IconDot";
import {
  ModalHeader
} from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader";
import {ModalClose} from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalClose/ModalClose";
import {Icon28Close} from "@telegram-apps/telegram-ui/dist/icons/28/close";

interface UserListModalProps {
  open?: boolean;
  onOpenChange: (value: boolean) => void;
  users?: User[];
  title?: string;
  after?: string | ReactNode;
  loading?: boolean
}

const UserListModal = ({
                         open = true,
                         onOpenChange,
                         users = [],
                         after = "",
                         title = "",
                         loading = false
                       }: UserListModalProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();


  const handleUserClick = (user: User) => {
    const tab = determineCurrentTab(location.pathname);
    if (tab) {
      dispatch(navigateWithinTab(tab, `/${tab}/user-profile/${user.id}`, navigate));
    }
  };

  return (
    <Modal
      dismissible
      header={<ModalHeader
        after={<ModalClose><Icon28Close
          style={{color: 'var(--tgui--plain_foreground)', cursor: "pointer"}}/></ModalClose>}/>}
      open={open}
      onOpenChange={onOpenChange}
    >
      <div style={{display: 'flex', flexDirection: 'column'}}>
        {title && <Text weight={"2"} style={{fontSize: 20, lineHeight: "26px", margin: 16}}>{title}</Text>}
        {loading &&
          <div style={{display: "flex", justifyContent: 'center', alignItems: 'center', margin: 16}}><Spinner size='m'/>
          </div>}
        {!loading && users.length === 0 && <Text style={{fontSize: 16, lineHeight: '21px', margin: 16}}>No users</Text>}
        {!loading && users.length > 0 && users.map((user) => (
          <div key={user.id}
               className="user-list-item"
               onClick={() => handleUserClick(user)}
          >
            <Avatar className="user-list-item__avatar" src={user.avatar} size={40}/>

            <div className="user-list-item__content">
              <Text weight={'2'}
                    style={{fontSize: 16, lineHeight: '21px'}}>{`${user.first_name} ${user.last_name}`}</Text>
              <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                <div style={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                  <IconDot color={'green'}/>
                  <Text weight={'1'} style={{
                    color: 'var(--tgui--hint_color)',
                    fontSize: 15,
                    lineHeight: '20px'
                  }}>{`${formatter.format(user.balance)}`}</Text>
                </div>

                <div style={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                  <IconDot color={'orange'}/>
                  <Text weight={'1'} style={{
                    color: 'var(--tgui--hint_color)',
                    fontSize: 15,
                    lineHeight: '20px'
                  }}>{`${formatter.format(user.position)}`}</Text>
                </div>
              </div>
            </div>

            {after && <div className="user-list-item__append">
              {typeof after === "string" ? (<Text style={{fontSize: 24}}>{after}</Text>) : after}
            </div>}
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default UserListModal;
