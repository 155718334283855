import React from "react";

interface IconDotProps {
  size?: number;
  color: 'green' | 'orange';
}

export const IconDot:React.FC<IconDotProps> = ({size = 8, color}) => {
  return (
    <div style={{marginTop: 1, width: size, height: size, background: color === 'green' ? '#42B842':'#FFB800', borderRadius: 9999}}/>
  );
};
