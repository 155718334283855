import React from 'react';
import {Spinner, Text} from '@telegram-apps/telegram-ui';
import Lottie from 'lottie-react';
import FlyingMoney from '../assets/FlyingMoney.json';
import {useTasksData} from "../hooks/useTasksData";
import {Task} from "./Task";
import {Reorder} from 'framer-motion';

const Tasks: React.FC = () => {
  const {tasks, loaded, error, handleTaskClaim} = useTasksData();

  return (
    <div className="container"
         style={{display: 'flex', flexDirection: 'column', padding: 0, height: '100dvh', overflowY: "scroll"}}>
      <div style={{
        padding: '24px 16px',
        display: 'flex',
        gap: '8px',
        flexDirection: 'column',
        alignItems: 'center',
        flexShrink: 0
      }}>
        <Lottie animationData={FlyingMoney} loop={true} style={{width: '44px', height: '44px'}}/>
        <div style={{display: 'flex', gap: '2px', flexDirection: 'column', alignItems: 'center'}}>
          <Text weight='1' style={{fontSize: '24px', lineHeight: '28px'}}>Tasks to Earn</Text>
          <Text weight='3' style={{textAlign: 'center', fontSize: '13px', lineHeight: '16px'}}>Complete quests and
            grow your profile</Text>
        </div>
      </div>

      <Reorder.Group
        as="div"
        onReorder={() => {
        }}
        values={tasks}
        axis="y"
        layoutScroll
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          flexGrow: 1,
          gap: 16,
          padding: 16
        }}
      >
        {error && <Text>Error: {error}</Text>}
        {!loaded && <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1}}><Spinner
          size="l"/></div>}
        {loaded && !error && tasks.map(task => (
          <Task task={task} key={task.id} onClaim={() => handleTaskClaim(task.id)}/>
        ))}
      </Reorder.Group>
    </div>
  );
};

export default Tasks;
