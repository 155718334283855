import React, {useRef} from "react";
import {Post} from "../../../api/types";
import {navigateWithinTab} from "../../navigation/stack/redux/navigationStackSlice";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {useNavigate} from "react-router-dom";
import {formatter} from "../../../app/helpers";
import {Avatar, Button, Divider, Image, Modal, Text} from '@telegram-apps/telegram-ui';
import {IconHeartFill} from "../assets/IconHeartFill";
import {IconHeart} from "../assets/IconHeart";
import {IconView} from "../assets/IconView";
import {useFeedData, useTrackView} from "../hooks/useFeedData";
import UserListModal from "../../../app/ui/components/UserListModal";
import {IconForward} from "../assets/IconForward";
import {IconTrash} from "../assets/IconTrash";
import {clearCreateForm} from "../../create/redux/creationSlice";
import {motion} from "framer-motion";

import "../../../app/ui/assets/repost.css"
import {IconDot} from "../../../app/ui/components/IconDot";
import {
  ModalHeader
} from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader";
import {ModalClose} from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalClose/ModalClose";
import {Icon28Close} from "@telegram-apps/telegram-ui/dist/icons/28/close";

interface FeedItemProps {
  item: Post;
  readonly?: boolean,
  divider?: boolean
}

const FeedItem: React.FC<FeedItemProps> = ({item, readonly = false, divider = false}) => {

  function timeAgo(date: string | Date): string {
    const now = new Date();
    const givenDate = new Date(date);

    const difference = now.getTime() - givenDate.getTime(); // Difference in milliseconds

    const seconds = Math.floor(difference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    const displaySeconds = seconds % 60;
    const displayMinutes = minutes % 60;
    const displayHours = hours % 24;
    const displayDays = days;

    let result = '';

    if (displayDays > 0) {
      return `${displayDays}d`;
    }
    if (displayHours > 0) {
      return `${displayHours}h`;
    }
    if (displayMinutes > 0) {
      return `${displayMinutes}m`;
    }
    if (displaySeconds >= 0) {
      return `${displaySeconds}s`;
    }

    return result;
  }

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    handleLikePost,
    handleUnlikePost,
    handleFetchLikes,
    handleFetchViews,
    handleDeletePost,
    isDeleting,
    postLikes,
    postViews,
    loadingLikes,
    loadingViews
  } = useFeedData();
  const elementRef = useRef<HTMLDivElement>(null);
  const currentUser = useAppSelector((state) => state.profile.currentUser);

  const [postIdToDelete, setPostIdToDelete] = React.useState<number | null>(null);


  useTrackView(item, elementRef);

  const navigateToUserProfile = () => {
    dispatch(navigateWithinTab('feed', `/feed/user-profile/${item.author.id}`, navigate));
  };

  const handleLike = () => {
    if (item.is_liked) {
      handleUnlikePost(item.id);
    } else {
      handleLikePost(item.id);
    }
  };

  const handleRepost = (id: number) => {
    dispatch(clearCreateForm());
    dispatch(navigateWithinTab('feed', `/feed/create/${id}`, navigate));
  }

  const handleDelete = (id: number) => {
    setPostIdToDelete(id);
  }

  const cancelDelete = () => {
    setPostIdToDelete(null);
  }

  const confirmDelete = async () => {
    if (postIdToDelete !== null) {

      await handleDeletePost(postIdToDelete);
      setPostIdToDelete(null);
    }
  }

  const [isLikedByModalOpen, setIsLikedByModalOpen] = React.useState(false);
  const [isViewedModalOpen, setIsViewedModalOpen] = React.useState(false);

  const onChangeIsLikedByModalOpen = (value: boolean) => {
    if (value) {
      handleFetchLikes(item.id)
    } else {
      setIsLikedByModalOpen(value);
    }
  }

  const onChangeIsViewedModalOpen = (value: boolean) => {
    if (value) {
      handleFetchViews(item.id);
    } else {
      setIsViewedModalOpen(value);
    }
  }

  return (
    <motion.div
      ref={elementRef}
      animate={{x: 0, opacity: 1}}
      exit={{x: -300, opacity: 0}}
      style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', gap: '6px'}}
    >
      <button
        onClick={() => readonly ? null : navigateToUserProfile()}
        style={{
          padding: '0px',
          border: 'none',
          background: 'var(--tgui--background_color)',
          color: 'var(--tgui--text_color)',
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'row',
          gap: '8px',
          alignItems: 'center'
        }}>
        <Avatar src={item.author.avatar} size={28}/>
        <div style={{width: '100%', display: 'flex', flexDirection: 'column', gap: '0px'}}>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '6px',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <Text weight='2' style={{
              lineHeight: '21px',
              fontSize: '16px'
            }}>{`${item.author.first_name} ${item.author.last_name}`}</Text>
            <Text weight='3'
                  style={{fontSize: '13px', color: 'var(--tgui--hint_color)'}}>{`${timeAgo(item.created)}`}</Text>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', gap: '8px'}}>
            <div style={{display: 'flex', flexDirection: 'row', gap: '2px', alignItems: 'center'}}>
              <IconDot color={'green'}/>
              <Text weight='1' style={{
                lineHeight: '13px',
                fontSize: '10px',
                color: 'var(--tgui--hint_color)'
              }}>{`${item.author.balance}`}</Text>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', gap: '2px', alignItems: 'center'}}>
              <IconDot color={'orange'}/>
              <Text weight='1' style={{
                lineHeight: '13px',
                fontSize: '10px',
                color: 'var(--tgui--hint_color)'
              }}>{`${formatter.format(item.author.position)}`}</Text>
            </div>
          </div>
        </div>
      </button>
      <Text weight='3' style={{lineHeight: '21px', fontSize: '16px'}}>{`${item.text}`}</Text>
      {item.media && (
        <Image
          src={item.media}
          style={{width: '100%', height: '154px'}}
        />
      )}

      {item.repost && <div className="reposted-post"><FeedItem item={item.repost} readonly/></div>}

      {!readonly &&
        <div style={{paddingTop: "6px", display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
          <div style={{display: "flex", flexDirection: "row", gap: "16px"}}>
            <button
              onClick={handleLike}
              style={{
                background: "var(--tgui--background_color)",
                cursor: "pointer",
                padding: "0px",
                border: "none",
                display: "flex",
                gap: "4px",
                alignItems: "center",
                userSelect: "none"
              }}
            >
              {item.is_liked ? (<IconHeartFill/>) : (<IconHeart/>)}
              {item.likes > 0 && <Text
                onClick={(e) => {
                  e.preventDefault();
                  setIsLikedByModalOpen(true);
                  e.stopPropagation();
                }}
                weight="3"
                style={{
                  color: "var(--tgui--hint_color)",
                  fontSize: "13px",
                  lineHeight: "16px",
                  userSelect: "none"
                }}>{formatter.format(item.likes)}</Text>}
            </button>
            <button
              onClick={() => handleRepost(item.id)}
              style={{
                background: "var(--tgui--background_color)",
                cursor: "pointer",
                padding: "0px",
                border: "none",
                display: "flex",
                gap: "4px",
                alignItems: "center",
                userSelect: "none"
              }}
            >
              <IconForward/>
            </button>
            {currentUser !== null && item.author.id === currentUser.id && <button
              onClick={() => handleDelete(item.id)}
              style={{
                background: "var(--tgui--background_color)",
                cursor: "pointer",
                padding: "0px",
                border: "none",
                display: "flex",
                gap: "4px",
                alignItems: "center",
                userSelect: "none"
              }}
            >
              <IconTrash/>
            </button>}
          </div>

          <div
            style={{
              display: "flex",
              gap: "4px",
              alignItems: "center"
            }}
          >
            <Text
              onClick={() => {
                setIsViewedModalOpen(true);
              }}
              weight="3"
              style={{
                color: "var(--tgui--hint_color)",
                fontSize: "13px",
                lineHeight: "16px",
                userSelect: "none",
                cursor: "pointer"
              }}>{formatter.format(item.views)}</Text>
            <IconView/>
          </div>
        </div>}


      <UserListModal
        open={isLikedByModalOpen}
        onOpenChange={onChangeIsLikedByModalOpen}
        users={postLikes[item.id] || []}
        title="Liked by"
        after={<IconHeartFill/>}
        loading={loadingLikes}
      />

      <UserListModal
        open={isViewedModalOpen}
        onOpenChange={onChangeIsViewedModalOpen}
        users={postViews[item.id] || []}
        title="Viewed by"
        after={<IconView/>}
        loading={loadingViews}
      />

      <Modal
        header={<ModalHeader
          after={<ModalClose><Icon28Close style={{color: 'var(--tgui--plain_foreground)', cursor: "pointer"}}/></ModalClose>}/>}
        dismissible
        open={postIdToDelete !== null}
        onOpenChange={(value) => !value ? cancelDelete() : null}
      >
        <div style={{display: 'flex', flexDirection: 'column', padding: 16, gap: 16}}>
          <Text weight='2' style={{fontSize: '20px', lineHeight: '26px', marginBottom: 16}}>Are you sure you want to
            delete this
            post?</Text>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '8px'
          }}>
            <Button onClick={cancelDelete} stretched size="l" mode="gray">Cancel</Button>
            <Button onClick={confirmDelete} loading={isDeleting} stretched size="l" mode="filled">Delete</Button>
          </div>
        </div>
      </Modal>

      {divider && <Divider style={{marginTop: 4}}/>}
    </motion.div>
  );
};

export default FeedItem;
