import React, {useCallback, useEffect, useRef} from 'react';
import FeedItem from './FeedItem';
import {useFeedData} from '../hooks/useFeedData';
import {useAppDispatch} from '../../../app/hooks';
import {clearCreateForm} from '../../create/redux/creationSlice';
import {useNavigate} from 'react-router-dom';
import {navigateWithinTab} from '../../navigation/stack/redux/navigationStackSlice';
import {Button, Spinner, TabsList, Text} from '@telegram-apps/telegram-ui';
import {TabsItem} from '@telegram-apps/telegram-ui/dist/components/Navigation/TabsList/components/TabsItem/TabsItem';
import {IconPencil} from '../../profile/assets/IconPencil';
import {useProfileData} from '../../profile';
import {AnimatePresence} from "framer-motion";

const Feed: React.FC = () => {
  const dispatch = useAppDispatch();
  const {items, loading, error, selectedFeedType, handleFeedTypeChange, fetchMoreSelectedFeed} = useFeedData();
  useProfileData(null);

  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(() => {
    const container = containerRef.current;
    if (container) {
      const scrollTop = container.scrollTop;
      const scrollHeight = container.scrollHeight;
      const clientHeight = container.clientHeight;

      const remainingScroll = scrollHeight - scrollTop - clientHeight;

      if (remainingScroll < 300 && !loading) {
        fetchMoreSelectedFeed();
      }
    }
  }, [loading, fetchMoreSelectedFeed]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  const handleCreateTap = () => {
    dispatch(clearCreateForm());
    dispatch(navigateWithinTab('feed', `/feed/create`, navigate));
  };

  const setFeedType = (feedType: string) => {
    handleFeedTypeChange(feedType);
  }

  return (
    <div ref={containerRef} className="container"
         style={{display: "flex", flexDirection: 'column', padding: 0, height: '100dvh'}}>
      <div style={{
        position: 'sticky',
        top: 0,
        zIndex: 10,
        paddingLeft: '32px',
        paddingRight: '32px',
        backgroundColor: 'var(--tg-theme-secondary-bg-color)',
        display: "flex",
        justifyContent: "center"
      }}>
        <TabsList>
          <TabsItem onClick={() => setFeedType('all')} selected={selectedFeedType === 'all'}>
            Recent
          </TabsItem>
          <TabsItem onClick={() => setFeedType('top24')} selected={selectedFeedType === 'top24'}>
            Daily Top
          </TabsItem>
          <TabsItem onClick={() => setFeedType('top')} selected={selectedFeedType === 'top'}>
            Overall Top
          </TabsItem>
        </TabsList>
      </div>

      {error && (
        <div style={{padding: 16}}>
          <Text>Error: {error}</Text>
        </div>
      )}

      {loading && items.length === 0 && (
        <div
          style={{
            flexGrow: 1,
            padding: 16,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Spinner size="l"/>
        </div>
      )}

      {items.length !== 0 && (
        <>
          <div style={{display: 'flex', flexDirection: 'column', gap: '10px', padding: 16}}>
            <AnimatePresence>
              {items.map((item, index) => (
                <FeedItem item={item} key={item.id} divider={index < items.length - 1}/>
              ))}
            </AnimatePresence>
          </div>

          {loading && (
            <div
              style={{
                flexGrow: 1,
                padding: 16,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Spinner size="m"/>
            </div>
          )}

          <Button
            onClick={handleCreateTap}
            size="s"
            before={<IconPencil/>}
            style={{
              position: 'fixed',
              bottom: 'calc(10px + var(--tab-bar-height))',
              right: '10px',
            }}
          >
            Post
          </Button>
        </>
      )}

    </div>
  );
}

export default Feed;
