import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import { SDKProvider } from '@telegram-apps/sdk-react';

import '@telegram-apps/telegram-ui/dist/styles.css';
import './index.css';

import WebApp from '@twa-dev/sdk';

WebApp.ready();
WebApp.expand();
WebApp.setHeaderColor('#000000');

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <SDKProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </SDKProvider>
  </React.StrictMode>
)