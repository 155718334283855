import {useCallback, useEffect} from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  fetchBoosters,
  fetchFollowers,
  fetchFollowing,
  fetchPosts,
  fetchProfile,
  followProfile,
  unfollowProfile
} from '../redux/profileSlice';
import { RootState } from '../../../app/store';

export const useProfileData = (userId: string | null) => {
  const dispatch = useAppDispatch();

  const profile = useAppSelector((state: RootState) =>
    userId === null ? state.profile.currentUser : state.profile.profiles[userId]
  );

  const isSelfProfile = useAppSelector((state: RootState) => state.profile.currentUser?.id === profile?.id);

  const postsLoading = useAppSelector((state: RootState) => state.profile.postsLoading);
  const loading = useAppSelector((state: RootState) => state.profile.loading);
  const error = useAppSelector((state: RootState) => state.profile.error);

  const loadingFollowers = useAppSelector((state) => state.profile.loadingFollowers);
  const loadingFollowing = useAppSelector((state) => state.profile.loadingFollowing);
  const loadingBoosters = useAppSelector((state) => state.profile.loadingBoosters);
  const followers = useAppSelector((state) => state.profile.followers);
  const following = useAppSelector((state) => state.profile.following);
  const boosters = useAppSelector((state) => state.profile.boosters);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(fetchProfile(userId)).unwrap();
        dispatch(fetchPosts({userId: response.data.id}));
      } catch (error) {
        console.error('Failed to fetch profile or posts:', error);
      }
    };

    fetchData();
  }, [dispatch, userId]);

  const handleFollow = (userId: number) => {
    dispatch(followProfile(userId));
  }

  const handleUnfollow = (userId: number) => {
    dispatch(unfollowProfile(userId));
  }

  const handleFetchFollowers = (userId: number) => {
    dispatch(fetchFollowers(userId));
  }

  const handleFetchFollowing = (userId: number) => {
    dispatch(fetchFollowing(userId));
  }

  const handleFetchBoosters = (userId: number) => {
    dispatch(fetchBoosters(userId));
  }

  const handleFetchMorePosts = useCallback(() => {
    if (postsLoading || profile === null) return

    if (profile.postsCursor !== undefined && profile.postsCursor !== "0") {
      dispatch(fetchPosts({ userId: profile.id, cursor: profile.postsCursor }));
    }
  }, [dispatch, postsLoading, profile]);

  return {
    profile,
    isSelfProfile,
    postsLoading,
    loading,
    error,

    loadingFollowers,
    loadingFollowing,
    loadingBoosters,

    followers,
    following,
    boosters,

    handleFollow,
    handleUnfollow,
    handleFetchFollowers,
    handleFetchFollowing,
    handleFetchBoosters,
    handleFetchMorePosts
  };
};
