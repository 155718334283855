import {Reorder} from "framer-motion";
import {Task as ITask} from "../../../api/types";
import "./Task.css";
import React from "react";
import {Button, Text} from "@telegram-apps/telegram-ui";
import {formatter} from "../../../app/helpers";
import MoneyBag from "../assets/MoneyBag.json";
import Lottie, {LottieRefCurrentProps} from "lottie-react";
import {IconDot} from "../../../app/ui/components/IconDot";

interface TaskProps {
  task: ITask;
  onClaim: () => Promise<void>;
}

export const Task: React.FC<TaskProps> = ({task, onClaim}) => {
  const ref = React.useRef<LottieRefCurrentProps | null>(null);
  const [loading, setLoading] = React.useState(false);

  const handleClaim = async () => {
    setLoading(true);
    ref.current?.play();
    await onClaim();
    setLoading(false);
  }

  return (
    <Reorder.Item
      as="div"
      className="task-card"
      value={task}
      dragListener={false}
      animate={{opacity: 1}}
      exit={{opacity: 0}}
    >
      <Lottie lottieRef={ref} animationData={MoneyBag} loop={true} onLoopComplete={() => ref.current?.pause()}
              autoplay={false} style={{alignSelf: 'center', width: 60, height: 60}}/>

      <div className="task-card__text">
        <Text
        weight="2"
        style={{fontSize: 'var(--tgui--text--font_size)', lineHeight: 'var(--tgui--text--line_height)'}}
      >{task.title}</Text>

        <div style={{display: 'flex', alignItems: 'center', gap: 4, color: 'var(--tgui--subtitle_text_color)'}}>
          <IconDot color={'green'}/>
          <Text weight="1" style={{fontSize: '16px', lineHeight: '16px'}}>{formatter.format(task.reward)}</Text>
        </div>

        <Text
          weight="3"
          style={{
            color: 'var(--tgui--hint_color)',
            fontSize: 'var(--tgui--subheadline2--font_size)',
            lineHeight: 'var(--tgui--subheadline2--line_height)'
          }}
        >{task.description}</Text>
      </div>


      <Button
        disabled={task.is_done || !task.is_claimable}
        size="s"
        mode="filled"
        style={{
          alignSelf: 'center',
          flexShrink: 0,
          ...(task.is_done && {background: '#31D158'})
        }}
        onClick={handleClaim}
        loading={loading}
      >{task.is_done ? 'Claimed!' : 'Claim'}</Button>
    </Reorder.Item>
  );
};
