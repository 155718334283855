import { useEffect, useCallback, useState, RefObject } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {deletePost, fetchFeed, fetchLikes, fetchViews, likePost, unlikePost, viewPost} from '../redux/feedSlice';
import { RootState } from '../../../app/store';
import { Post } from '../../../api/types';
import {useLocation} from "react-router-dom";
import {determineCurrentTab} from "../../navigation/stack/redux/navigationStackSlice";
import {fetchPosts} from "../../profile";

export type FeedType = 'all' | 'top24' | 'top';

export const useTrackView = (post: Post, ref: RefObject<HTMLDivElement>) => {
  const dispatch = useAppDispatch();

  const sendView = useCallback(async () => {
    if (!post.is_viewed) {
      dispatch(viewPost(post.id));
    }
  }, [dispatch, post]);

  useEffect(() => {
    if (!ref.current) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const timer = setTimeout(sendView, 1000);
            return () => clearTimeout(timer);
          }
        });
      },
      { threshold: 0.5 } // Trigger when 50% of the component is visible
    );

    observer.observe(ref.current);

    return () => {
      if (ref.current) observer.unobserve(ref.current);
    };
  }, [ref, sendView]);
};

export const useFeedData = () => {
  const dispatch = useAppDispatch();

  const loading = useAppSelector((state: RootState) => state.feed.loading);
  const error = useAppSelector((state: RootState) => state.feed.error);
  const feeds = useAppSelector((state: RootState) => state.feed.feeds);

  const postLikes = useAppSelector((state: RootState) => state.feed.postLikes);
  const postViews = useAppSelector((state: RootState) => state.feed.postViews);
  const loadingLikes = useAppSelector((state: RootState) => state.feed.loadingLikes);
  const loadingViews = useAppSelector((state: RootState) => state.feed.loadingViews);

  const [isDeleting, setIsDeleting] = useState(false);

  const [selectedFeedType, setSelectedFeedType] = useState<FeedType>('all');

  const location = useLocation();
  const currentTab = determineCurrentTab(location.pathname) ?? 'feed';

  const currentUser = useAppSelector((state: RootState) => state.profile.currentUser);

  useEffect(() => {
    if (!feeds.all.loaded) {
      dispatch(fetchFeed({name: 'all'}));
    }
  }, [dispatch, feeds.all.loaded]);

  const fetchSelectedFeed = useCallback(
    async (feedType: FeedType) => {
      if (!feeds[feedType].loaded) {
        dispatch(fetchFeed({name: feedType}));
      }
    },
    [dispatch, feeds]
  );

  const fetchMoreSelectedFeed = useCallback(() => {
    if (loading) return
    const selectedFeedCursor = feeds[selectedFeedType].cursor;

    if (selectedFeedCursor !== null && selectedFeedCursor !== 0) {
      dispatch(fetchFeed({ name: selectedFeedType, cursor: selectedFeedCursor }));
    }
  }, [dispatch, selectedFeedType, loading, feeds]);

  const handleFeedTypeChange = (newType: string) => {
    setSelectedFeedType(newType as FeedType);
    dispatch(fetchFeed({name: newType as FeedType}));
  };

  useEffect(() => {
    if (feeds[selectedFeedType].loaded) {
      fetchSelectedFeed(selectedFeedType);
    }
  }, [selectedFeedType, fetchSelectedFeed, feeds]);

  const handleLikePost = (postID: number) => {
    dispatch(likePost(postID));
  };

  const handleUnlikePost = (postID: number) => {
    dispatch(unlikePost(postID));
  };

  const handleFetchLikes = (postId: number) => {
    dispatch(fetchLikes(postId));
  };

  const handleFetchViews = (postId: number) => {
    dispatch(fetchViews(postId));
  };

  const handleDeletePost = async (postId: number) => {
    setIsDeleting(true);
    await dispatch(deletePost(postId));
    if (currentTab === 'feed') {
      await dispatch(fetchFeed({name: selectedFeedType}));
    } else if (currentTab === 'profile' && currentUser) {
      await dispatch(fetchPosts({userId: currentUser.id, cursor: currentUser.postsCursor}));
    }

    setIsDeleting(false);
  }

  const selectedItems: Post[] = feeds[selectedFeedType].items || [];

  return {
    items: selectedItems,
    loading,
    error,
    handleLikePost,
    handleUnlikePost,
    handleFetchLikes,
    handleFetchViews,
    handleDeletePost,
    isDeleting,
    postLikes,
    postViews,
    loadingLikes,
    loadingViews,
    selectedFeedType,
    handleFeedTypeChange,
    fetchMoreSelectedFeed
  };
};
