import React from 'react';
import {Button, Modal, Text} from '@telegram-apps/telegram-ui';
import {IconLightning} from '../assets/IconLightning';
import {formatter} from '../../../app/helpers';
import {User} from '../../../api/types';
import BuyMenu from './BuyMenu';
import {useAppDispatch} from '../../../app/hooks';
import {fetchProfile} from '../redux/profileSlice';
import {IconDot} from "../../../app/ui/components/IconDot";
import {
  ModalHeader
} from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader";
import {ModalClose} from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalClose/ModalClose";
import {Icon28Close} from "@telegram-apps/telegram-ui/dist/icons/28/close";

interface ProfilePositionProps {
  user: User | null;
  type: 'profile' | 'leaderboard';
  loading?: boolean;
}

const ProfilePositionBanner: React.FC<ProfilePositionProps> = ({user, type, loading = false}) => {
  const dispatch = useAppDispatch();

  return (
    <div style={{
      padding: 12,
      background: type === 'leaderboard' ? '#0000000A' : '#FF8A0021',
      minHeight: 41,
      ...(type === 'profile' && {borderRadius: 12})
    }}
    >
      {!loading && <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <div style={{display: "flex", gap: "8px"}}>
          <Text style={{
            width: 40,
            height: 40,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "34px"
          }}>🏆</Text>
          <div style={{display: "flex", flexDirection: "column", gap: "0px"}}>
            <div style={{display: "flex", flexDirection: "row", gap: "5px"}}>
              <Text weight="2" style={{
                fontSize: "16px",
                lineHeight: "21px",
                color: "#FF8A00"
              }}>{`#${formatter.format(user?.position ?? 0)}`}</Text>
              <Text weight="2" style={{fontSize: "16px", lineHeight: "21px"}}>in Global Top</Text>
            </div>
            <div style={{display: "flex", alignItems: "center", gap: "4px"}}>
              <IconDot color={"green"}/>
              <Text weight="1" style={{
                fontSize: "15px",
                lineHeight: "20px",
                color: "var(--tgui--hint_color"
              }}>{formatter.format(user?.balance ?? 0)}</Text>
            </div>
          </div>
        </div>

        <Modal
          dismissible
          header={<ModalHeader
            after={<ModalClose><Icon28Close style={{color: 'var(--tgui--plain_foreground)', cursor: "pointer"}}/></ModalClose>}/>}
          onOpenChange={(isOpen) => {
            if (!isOpen) {
              dispatch(fetchProfile(`${user?.id}`));
            }
          }}
          trigger={<Button style={{
            padding: "10px 16px",
            background: "#31D158",
            borderRadius: "20px",
            height: "40px",
            gap: 6,
            "--tgui--subheadline2--line_height": "20px"
          } as any}
                           before={<IconLightning/>}>Boost</Button>}
        >
          {!!user && <BuyMenu userId={user?.id}/>}
        </Modal>
      </div>}
    </div>
  );
};

export default ProfilePositionBanner;
