import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {Task} from "../../../api/types";
import {claim, fetchTasksData} from "../services/tasksService";

interface TasksState {
  items: Task[];
  loading: boolean;
  loaded: boolean;
  error: string | null;
}

const initialState: TasksState = {
  items: [],
  loading: true,
  loaded: false,
  error: null
};

export const fetchTasks = createAsyncThunk(
  'tasks/fetchTasks',
  async () => {
    const data = await fetchTasksData();
    return data;
  }
);

export const claimTask = createAsyncThunk(
  'tasks/claimTask',
  async (taskId: number) => {
    await claim(taskId);
    return taskId;
  }
);

const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTasks.fulfilled, (state, action) => {
        state.items = action.payload.tasks.slice().sort((a: Task, b: Task) => {
          if (a.is_done !== b.is_done) {
            return a.is_done ? 1 : -1;
          }
          if (a.is_claimable !== b.is_claimable) {
            return a.is_claimable ? -1 : 1;
          }
          return a.reward - b.reward;
        });
        state.loading = false;
        state.loaded = true;
        state.error = null;
      })
      .addCase(fetchTasks.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTasks.rejected, (state, action) => {
        state.loading = false;
        state.loaded = true;
        state.error = action.error.message || 'Failed to fetch tasks';
      })
  },
});

export default tasksSlice.reducer;
