import {FeedResponse, ProfilesResponse} from '../../../api/types';
import {
  deletePost,
  fetchFeed,
  fetchPostLikes,
  fetchPostViews,
  likePost,
  unlikePost,
  viewPost
} from '../../../api/userApi';
import {mockMode} from "../../../config";
import {mockFeedResponse, mockUser} from "../../../api/mocks/mocks";

export const fetchFeedData = async (name: string, cursor?: number): Promise<FeedResponse> => {
  if (mockMode) {
    return new Promise((resolve) =>
      setTimeout(() => {
        if (cursor) {
          resolve({posts: mockFeedResponse.posts.map((post, index) => ({...post, id: post.id - 50})), cursor: cursor.toString()});
        } else {
          resolve(JSON.parse(JSON.stringify(mockFeedResponseInstance)));
        }
      }, 1000)
    );
  } else {
    const response = await fetchFeed(name, cursor);
    return response.data;
  }
};

export const views = async (postID: number): Promise<ProfilesResponse> => {
  if (mockMode) {
    return new Promise((resolve) =>
      setTimeout(() => resolve({profiles: [mockUser, {...mockUser, id: 2}, {...mockUser, id: 3}]}), 1000)
    );
  } else {
    const response = await fetchPostViews(postID);
    return response.data;
  }
}

export const view = async (postID: number): Promise<void> => {
  if (mockMode) {
    return new Promise((resolve) => setTimeout(() => resolve(), 1000));
  } else {
    const response = await viewPost(postID);
    return response.data;
  }
};

export const likes = async (postID: number): Promise<ProfilesResponse> => {
  if (mockMode) {
    return new Promise((resolve) =>
      setTimeout(() => resolve({profiles: [mockUser, {...mockUser, id: 2}, {...mockUser, id: 3}]}), 1000)
    );
  } else {
    const response = await fetchPostLikes(postID);
    return response.data;
  }
}

export const like = async (postID: number): Promise<void> => {
  const response = await likePost(postID);
  return response.data;
};

export const unlike = async (postID: number): Promise<void> => {
  const response = await unlikePost(postID);
  return response.data;
};

export const remove = async (postID: number): Promise<void> => {
  if (mockMode) {
    return new Promise((resolve) =>
      setTimeout(() => {
        mockFeedResponseInstance.posts.splice(0, 1);
        resolve();
      }, 1000)
    );
  } else {
    const response = await deletePost(postID);
    return response.data;
  }
}

const mockFeedResponseInstance: FeedResponse = JSON.parse(JSON.stringify(mockFeedResponse));
